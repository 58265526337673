import GaugeComponent from 'react-gauge-component';

interface ScoreGraphProps {
  score: number;
}
export default function ScoreGraph({ score }: ScoreGraphProps) {
  return (
    <GaugeComponent
      style={{ width: '100%', textAlign: 'center' }}
      marginInPercent={{ top: 0.03, bottom: 0.04, left: 0.0, right: 0.0 }}
      type="semicircle"
      arc={{
        colorArray: ['#F04438', '#EF6820', '#A6EF67', '#32D583'],
        padding: 0.02,
        subArcs: [
          { limit: 8 },
          { limit: 16 },
          { limit: 24 },
          { limit: 32 },
          { limit: 44 },
          { limit: 64 },
          {},
        ],
      }}
      pointer={{ type: 'blob', animationDelay: 0 }}
      value={score}
      labels={{
        valueLabel: {
          style: {
            fontSize: '35px',
            fill: '#000',
            textShadow: 'none',
          },
        },
        markLabel: {
          hideMinMax: true,
          type: 'outer',
        },
      }}
    />
  );
}
