export const SIGN_IN = 'SIGN_IN';
export const ONBOARDING = 'ONBOARDING';
export const MY_ONBOARDINGS = 'MY_ONBOARDINGS';
export const ALL_ONBOARDINGS = 'ALL_ONBOARDINGS';
export const UNASSIGNED_ONBOARDINGS = 'UNASSIGNED_ONBOARDINGS';
export const TAXABLE_ENTITIES = 'TAXABLE_ENTITIES';
export const TAXABLE_ENTITY_CREDENTIALS = 'TAXABLE_ENTITY_CREDENTIALS';
export const TAXABLE_ENTITIES_TABLE = 'TAXABLE_ENTITIES_TABLE';
export const TAX_ARREARS = 'TAX_ARREARS';
export const TAX_ARREAR = 'TAX_ARREAR';
export const TEAMS = 'TEAMS';
export const REFERRALS = 'REFERRALS';
export const WORKFLOW_TEMPLATES = 'WORKFLOW_TEMPLATES';
export const FISCAL_REGIMES = 'FISCAL_REGIMES';
export const CURRENT_SUBSCRIPTIONS = 'CURRENT_SUBSCRIPTIONS';
export const WORKFLOWS = 'WORKFLOWS';
export const COUPONS = 'COUPONS';
export const COMPLIANCE_OPINION_STATUS = 'COMPLIANCE_OPINION_STATUS';
export const TAXABLE_ENTITY_PREFERENCES = 'TAXABLE_ENTITY_PREFERENCES';
export const DECLARATIONS = 'DECLARATIONS';
export const FIXED_ASSETS = 'FIXED_ASSETS';
export const CFDI_PDF_BY_ID = 'CFDI_PDF_BY_ID';
export const PERSONAL_DEDUCTIONS = 'PERSONAL_DEDUCTIONS';
export const DECLARATION_BALANCE = 'DECLARATION_BALANCE';
