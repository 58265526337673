import { call, put } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import * as Sentry from '@sentry/react';
import api from '@api';
import { setUserDataToOpenReplay } from '@util/OpenReplay';
import gtmEvent from '@util/gtmEvent';
import { identifySegment } from '@util/Segment';
import { NotificationManager } from '@components';
import {
  load as loadIntercom,
  boot as bootIntercom,
  formatInfo,
} from '@util/Intercom';
// eslint-disable-next-line import/extensions
import { getApiConfigSSO } from 'shared/util/sso';
import {
  loginUserSuccess,
  loginUserFailure,
  updateUserOtpToken,
} from '../actions';

export default function* ({
  payload: { navigate, user, captchaToken, featureClient, SSO },
}) {
  let withOtpCode = false;
  try {
    const { email, password, code } = user;
    const payload = {
      email,
    };
    if (code && user?.otp_token) {
      payload.otp_code = code;
      payload.otp_token = user.otp_token;
      withOtpCode = true;
    } else {
      payload.password = password;
      payload.captcha_token = captchaToken;
    }
    const response = yield call(api.post, '/sign_in', payload);
    response.data.email = payload.email;

    if (response?.data?.otp_token) {
      yield put(updateUserOtpToken(response));
      return;
    }

    const { access_token, phone_number, id, first_name, taxable_entity } =
      response.data;
    localStorage.setItem('user_id', access_token);
    if (!SSO?.isFromSSO) {
      yield call(StatusHandler, {
        response,
        success: loginUserSuccess,
        failure: loginUserFailure,
        options: { silent: true },
      });
      navigate('/');
    }

    // Google tag manager event submit
    gtmEvent('AppEvent', 'loginSuccess');
    // Sentry data set scope
    Sentry.configureScope((scope) => {
      scope.setUser({
        id,
        email,
        username: first_name,
        phone_number,
      });
    });
    user = response.data;
    setUserDataToOpenReplay({
      user,
      taxable_entity,
    });
    const featureFlags = yield featureClient.identify({
      kind: 'user',
      key: id,
      email,
      firstName: first_name,
      hasSubscription: taxable_entity?.status !== 'demo',
    });
    identifySegment(user.id, featureFlags);
    yield loadIntercom();
    yield bootIntercom(formatInfo({ user }));

    const apiSSOConfig = getApiConfigSSO(SSO?.source, SSO?.params);
    if (SSO?.isFromSSO && !!apiSSOConfig) {
      const redirectUrl = yield call(apiSSOConfig.get, apiSSOConfig.config);
      window.location.replace(redirectUrl);
    }
  } catch (error) {
    if (withOtpCode) {
      NotificationManager.error(
        'El código de verificación es incorrecto',
        'Verificación en dos pasos',
      );
      return;
    }
    yield call(ErrorHandler, {
      error,
      action: loginUserFailure,
    });
  }
}
