import { ComponentProps, useMemo } from 'react';
import dayjs from 'dayjs';
import { Chip } from '@konta/ui';
import useComplianceOpinionStatus from 'shared/hooks/useComplianceOpinionStatus';
import { complianceOpinionStatusColorDict } from 'shared/constants/documents';
import useTaxableEntityShared from 'shared/hooks/useSharedTaxableEntity';
import getAuthorizationAxiosConfig from 'shared/util/getAuthorizationAxiosConfig';
import useKontaScore from 'shared/hooks/useKontaScore';

export default function useMetricGroup() {
  const { taxableEntityId, userAccessToken } = useTaxableEntityShared();
  const authorizedAxiosConfig = getAuthorizationAxiosConfig(userAccessToken);
  const { kontaScore } = useKontaScore(authorizedAxiosConfig);
  const { complianceOpinionStatusLoading, complianceOpinionStatus } =
    useComplianceOpinionStatus(
      {
        params: {
          taxable_entity_id: taxableEntityId,
        },
        ...authorizedAxiosConfig,
      },
      {
        staleTime: 15 * 1000,
      },
    );
  return useMemo(() => {
    const complianceOpinionStatusLabel =
      complianceOpinionStatus?.status || 'Sin opinión';
    const complianceOpinionStatusCheckedAt = complianceOpinionStatus?.checked_at
      ? dayjs(complianceOpinionStatus.checked_at).format(
          '[Actualizado el día] DD [de] MMMM [del] YYYY [a las] h:mm A',
        )
      : 'Sin fecha';
    const complianceOpinionStatusColor = complianceOpinionStatusColorDict[
      complianceOpinionStatusLabel
    ] as ComponentProps<typeof Chip>['color'];
    const lastUpdated = kontaScore?.last_update
      ? dayjs(kontaScore.last_update).format('DD/MM/YYYY')
      : '-';
    const satQualification = kontaScore?.status || '-';
    const kontaScoreNumber = kontaScore?.score || 0;
    return {
      taxableEntityId,
      complianceOpinionStatusLabel,
      complianceOpinionStatusCheckedAt,
      complianceOpinionStatusLoading,
      complianceOpinionStatusColor,
      lastUpdated,
      satQualification,
      kontaScoreNumber,
    };
  }, [
    complianceOpinionStatus?.checked_at,
    complianceOpinionStatus?.status,
    complianceOpinionStatusLoading,
    kontaScore?.last_update,
    kontaScore?.score,
    kontaScore?.status,
    taxableEntityId,
  ]);
}
