import { createContext } from 'react';
import { Flex } from '@konta/ui';
import FullContent from 'backoffice/layouts/FullContent';
import BlockUi from 'shared/components/BlockUi';
import Loader from 'shared/components/Loader';
import { TaxableEntity } from 'types/entities';
import { CustomerFromComponent } from 'backoffice/store/types';
import CustomerHeader from './CustomerHeader';
import useCustomer from './useCustomer';
import { Content, Title } from './styled';
import useFromComponent from './useFromComponent';

export const CustomerProvider = createContext<TaxableEntity | null>(null);
interface CustomerProps {
  from?: CustomerFromComponent;
}
export default function Customer({ from = 'customers' }: CustomerProps) {
  const BodyComponent = useFromComponent(from);
  const { taxableEntity, taxableEntitiesLoading, goToCustomers } =
    useCustomer();

  return (
    <CustomerProvider.Provider value={taxableEntity}>
      <BlockUi blocking={taxableEntitiesLoading} />
      <FullContent
        onClose={goToCustomers}
        content={
          <Content column>
            <CustomerHeader />
            {taxableEntitiesLoading ? <Loader /> : <BodyComponent />}
          </Content>
        }
      />
    </CustomerProvider.Provider>
  );
}
