import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Row, Card, CardBody } from 'reactstrap';
import { Eye2Icon } from '@konta/icons';
import { getMonthAndYearDate } from '@util/Utils';
import toCurrency from '@util/toCurrency';
import {
  workflowSetTransition,
  addressFetch,
  uploadXmlCfdi,
} from '@redux/actions';
import { Colxx, Emoji, ButtonConfirmModal } from '@components';
import WorkflowLayout from '@components/WorkflowLayout';
import PrevPhaseButton from '@components/PrevPhaseButton';
import WorkflowHeader from '@components/WorkflowHeader';
import HelpCenterLink from '@components/HelpCenterLink';
import MissingPublicInvoicePreview from '../MissingPublicInvoicePreview';
import { AddPublicInvoice } from './addPublicInvoice/PublicInvoiceUpload';
// eslint-disable-next-line import/extensions
import NotCsdUploadInvoiceNextPhase from '../NotCsdUploadInvoiceNextPhase';

function NotCsdUploadInvoiceResico({ workflow, address }) {
  const dispatch = useDispatch();

  const [filesState, setFilesState] = useState({
    files: [],
    validFiles: false,
  });

  useEffect(() => {
    if (!address) {
      dispatch(addressFetch());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const primaryPhaseHandler = () => {
    dispatch(workflowSetTransition('phase-front'));
    const body = new FormData();

    body.append(
      'declaration_entry[declaration_id]',
      workflow.declarations[0].id,
    );

    filesState.files.forEach((file) => {
      body.append('declaration_entry[source_attributes][xml]', file);
    });

    body.append('declaration_entry[accounting_status]', 'is_deductible');
    body.append(
      'declaration_entry[accounting_date]',
      workflow.declarations[0].start_date,
    );
    body.append('declaration_entry[source_type]', 'Cfdi');
    body.append(
      'declaration_entry[taxable_entity_id]',
      workflow.taxable_entity_id,
    );

    dispatch(
      uploadXmlCfdi({ workflow_id: workflow.id, body, nextPhase: true }),
    );
  };

  const eventAddHandlers = {
    addedfile: (file) => {
      setFilesState({
        ...filesState,
        files: [...filesState.files, file],
        validFiles: true,
      });
    },
    removedfile: (file) => {
      const { files } = filesState;
      const newFileArray = files.filter(
        (item) => item.name === file.name && item.size === file.size,
      );
      setFilesState({
        ...filesState,
        files: newFileArray,
        validFiles: !!files.length,
      });
    },
  };

  const workflowTaxes = Object.entries(workflow).reduce(
    (acum, [key, value]) => {
      if (
        value > 0 &&
        (key === 'total_base_exempt_missing' ||
          key === 'total_base_with_iva_missing')
      ) {
        // TODO: improve this
        acum = {
          ...acum,
          [key]: value,
        };
      }
      return acum;
    },
    [],
  );

  return (
    <WorkflowLayout
      header={
        <WorkflowHeader
          title="Creación de factura manual"
          description="Si está en esta sección del proceso es porque aún no ha subido sus Sellos Digitales (CSD), por lo que debemos hacer una factura al Público en general. Debemos arreglarlo para asegurarnos que la declaración este correcta."
          videoId="GMmQBPhRaSA"
        />
      }
      actions={
        <>
          <PrevPhaseButton />
          <HelpCenterLink articleId="what_is_csd">
            <Emoji symbol="🚀" label="rocket" className="mr-2" />
            <span className="clickable-text-color">
              ¿Quieres automatizar esta factura? click aquí
            </span>
          </HelpCenterLink>
          <NotCsdUploadInvoiceNextPhase
            isNextBtnDisabled={filesState.files.length === 0}
            onNextBtnClick={primaryPhaseHandler}
          />
        </>
      }
    >
      <div className="d-flex align-items-center w-100">
        <div className="w-100">
          <Row className="mb-4">
            <Colxx xxs="12" md="12">
              <div className="w-80 w-sm-100">
                <h2 className="mb-4 pb-0">
                  <Emoji symbol="⚠️" label="warning" /> Aún no has subido tus
                  sellos digitales “CSD” a la plataforma para facturar. Es
                  necesario entrar al portal del SAT y emitir una factura al
                  público en general.
                </h2>
                <p className="text-muted mb-0">
                  Si tiene dudas de cómo realizar la factura, tenemos un
                  artículo explicando cómo hacerla correctamente.{' '}
                  <span>
                    <HelpCenterLink articleId="public_invoice">
                      Click aquí
                    </HelpCenterLink>{' '}
                    o igual tenemos un video{' '}
                    <HelpCenterLink articleId="public_invoice">
                      aquí
                    </HelpCenterLink>
                    .
                  </span>
                </p>
              </div>
            </Colxx>
          </Row>
          <Row className="mb-4">
            <Colxx xxs="12" md="3" className="d-flex justify-content-start">
              <ButtonConfirmModal
                className="w-100"
                header="Factura ejemplo"
                bodyClassName="d-flex"
                buttonColor="primary"
                buttonClassName="new-btn mr-3"
                handleSubmit={() => {}}
                confirmButton="OK"
                buttonLabel="Ver factura ejemplo"
                isButtonDisabled={false}
                outline
                modalSize="lg"
                lineIcon={Eye2Icon}
              >
                <MissingPublicInvoicePreview />
              </ButtonConfirmModal>
            </Colxx>

            <>
              {Object.keys(workflowTaxes).map((tax, index) => (
                <Colxx sm="12" md="6" key={index}>
                  <Card className="invoice-concept-card">
                    <CardBody>
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <h2 className="mb-0">Concepto {index + 1}</h2>
                        <HelpCenterLink
                          className="invoice-concept-card-help"
                          articleId="public_invoice"
                        >
                          <small className="clickable-text-color font-italic">
                            <i className="simple-icon-info ml-1 font-weight-bold" />{' '}
                            Ayuda
                          </small>
                        </HelpCenterLink>
                      </div>
                      <Row>
                        <Colxx>
                          <p className="font-weight-bold mb-1">
                            {'Cantidad: '}
                            <span className="font-weight-normal">1</span>
                          </p>
                          <p className="font-weight-bold mb-1">
                            {'Valor unitario (subtotal): '}
                            <span className="font-weight-normal">
                              ${parseFloat(workflowTaxes[tax])}
                            </span>
                          </p>
                          <p className="font-weight-bold mb-1">
                            {'Clave de producto y servicio: '}
                            <span className="font-weight-normal">01010101</span>
                          </p>
                          <p className="font-weight-bold mb-1">
                            {'Clave de unidad: '}
                            <span className="font-weight-normal">ACT</span>
                          </p>
                          <p className="font-weight-bold mb-3">
                            {'Descripción: '}
                            <span className="font-weight-normal">
                              Ventas al público en general del mes de{' '}
                              {getMonthAndYearDate(
                                new Date(workflow.start_date),
                              )}{' '}
                              {tax === 'total_base_with_iva_missing'
                                ? '16%'
                                : '0%'}
                            </span>
                          </p>
                        </Colxx>
                      </Row>
                      <h2 className="mb-2">Impuestos</h2>
                      <Row>
                        <Colxx>
                          <p className="font-weight-bold mb-1">
                            {'Tipo: '}
                            <span className="font-weight-normal">Traslado</span>
                          </p>
                          <p className="font-weight-bold mb-1">
                            {'Base: '}
                            <span className="font-weight-normal">
                              {toCurrency(+workflowTaxes[tax])}
                            </span>
                          </p>
                          <p className="font-weight-bold mb-1">
                            {'Impuesto: '}
                            <span className="font-weight-normal">002</span>
                          </p>
                          <p className="font-weight-bold mb-1">
                            {'¿Tasa o Cuota?: '}
                            <span className="font-weight-normal">Tasa</span>
                          </p>
                          <p className="font-weight-bold mb-1">
                            {'Valor de la tasa o cuota: '}
                            <span className="font-weight-normal">
                              {tax === 'total_base_with_iva_missing'
                                ? '0.160000'
                                : '0.000000'}
                            </span>
                          </p>
                        </Colxx>
                      </Row>
                    </CardBody>
                  </Card>
                </Colxx>
              ))}
            </>
          </Row>
          <Row className="mb-4">
            <Colxx sm="12">
              <h2 className="mb-4">
                El siguiente paso sería subir tu factura en formato(.xml) aquí.{' '}
                <Emoji symbol="👇🏼" label="point-down" className="mr-2" />
              </h2>
              <AddPublicInvoice events={eventAddHandlers} />
            </Colxx>
          </Row>
        </div>
      </div>
    </WorkflowLayout>
  );
}

const mapStateToProps = ({ workflows, addresses }) => {
  const {
    selected_workflow: { workflow, loading: wloading },
  } = workflows;
  const { address } = addresses;
  return { workflow, wloading, address };
};

export default connect(mapStateToProps)(NotCsdUploadInvoiceResico);
