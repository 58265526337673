import { styled } from '@konta/ui';

export const Wrapper = styled('div', {
  position: 'absolute',
  inset: '0',
  backgroundColor: '$white',
  overflow: 'hidden',
  display: 'flex',
  flex: 'initial',
  flexDirection: 'row',
});
export const Main = styled('div', {
  display: 'flex',
  flexShrink: 'initial',
  flexBasis: 'initial',
  flexDirection: 'column',
  flexGrow: 1,
  maxWidth: '100%',
  pb: '$40',
  '@lg': {
    background: 'none',
    maxWidth: 'calc(100% - 12px)',
    margin: '6px',
    borderRadius: '6px',
  },
});
export const TopBar = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '12px',
  flex: '0 0 auto',
  paddingLeft: '50px',
  backgroundColor: '$white',
  height: '75px',
  borderBottom: '1px solid $gray200',
  '@lg': {
    padding: '0px 12px 0px 15px',
  },
});
export const ContentWrapper = styled('div', {
  display: 'flex',
  flexShrink: 'initial',
  flexBasis: 'initial',
  flexDirection: 'row',
  flexGrow: 1,
  overflow: 'auto',
});
export const Content = styled('div', {
  display: 'flex',
  width: '100%',
  height: '100%',
});
export const CentralContent = styled('div', {
  display: 'flex',
  flexGrow: 2,
  flexDirection: 'column',
  flexBasis: '760px',
  background: '$white',
  minWidth: '0px',
  WebkitBoxFlex: '2',
});
export const LeftSidebar = styled('div', {
  position: 'relative',
  minWidth: '320px',
  maxWidth: '480px',
  WebkitBoxFlex: '1',
  flexGrow: 1,
  backgroundColor: '$white',
  borderLeft: '1px solid $gray200',
});
