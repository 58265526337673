import { styled } from '@konta/ui';

const FormWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  mt: '$24',
  gap: '$12',
  label: { marginBottom: 0 },
});

export default FormWrapper;
