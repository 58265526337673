import { create } from 'zustand';
import dayjs, { Dayjs } from 'dayjs';

type Tab = 'received' | 'emitted' | 'templates' | 'drafts';

interface CfdiStore {
  dateFilter: Dayjs;
  setDateFilter: (date: Dayjs) => void;
  selectedTab: Tab;
  setSelectedTab: (tab: Tab) => void;
}

const useCfdiStore = create<CfdiStore>((set, get) => ({
  dateFilter: dayjs(),
  setDateFilter: (date) => set({ dateFilter: date }),
  selectedTab: 'emitted',
  setSelectedTab: (tab) => set({ selectedTab: tab }),
}));

export default useCfdiStore;
