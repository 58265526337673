import { ComponentProps, ReactNode } from 'react';
import { CSS, Chip, Flex, HoverCard, Text } from '@konta/ui';
import { HelpCircleLineIcon } from '@konta/icons';
import Icon from 'shared/components/Icon';
import {
  MetricItem,
  MetricItemContent,
  MetricItemHeader,
  ChipContainer,
  MetricData,
  MetricGraph,
} from './styled';

export interface MetricCardProps {
  headerTitle: string;
  headerHoverText?: ReactNode;
  contentTitle?: string;
  contentDescription?: string;
  buttonsActions?: ReactNode;
  withChip?: boolean;
  chipProps?: ComponentProps<typeof Chip>;
  headerAlignStart?: boolean;
  withSmallContentTitle?: boolean;
  graph?: ReactNode;
  metricItemCss?: CSS;
}
export default function MetricCard({
  headerTitle,
  headerHoverText,
  contentTitle,
  contentDescription = '',
  buttonsActions,
  withChip,
  chipProps,
  headerAlignStart,
  withSmallContentTitle,
  graph,
  metricItemCss,
}: MetricCardProps) {
  return (
    <MetricItem css={metricItemCss}>
      <MetricData>
        <MetricItemHeader between start={headerAlignStart}>
          <Flex gap={6}>
            <Text color="gray700" medium xs lineHeight="xs">
              {headerTitle}
            </Text>
            {!!headerHoverText && (
              <HoverCard
                side="top"
                openDelay={0}
                triggerElement={
                  <Icon tabIndex={-1} role="button">
                    <HelpCircleLineIcon />
                  </Icon>
                }
              >
                {headerHoverText}
              </HoverCard>
            )}
          </Flex>
          {!!buttonsActions && buttonsActions}
        </MetricItemHeader>
        <MetricItemContent>
          {withChip && chipProps && (
            <ChipContainer>
              <Chip {...chipProps} />
            </ChipContainer>
          )}
          {withSmallContentTitle ? (
            <Text color="gray500" regular xs lineHeight="xs">
              {contentTitle}
            </Text>
          ) : (
            <Text color="gray900" regular l lineHeight="l">
              {contentTitle}
            </Text>
          )}
          {!!contentDescription && (
            <Text color="gray500" regular xs lineHeight="xs">
              {contentDescription}
            </Text>
          )}
        </MetricItemContent>
      </MetricData>
      {!!graph && <MetricGraph> {graph && graph}</MetricGraph>}
    </MetricItem>
  );
}
