import camelcaseKeys from 'camelcase-keys';
import { Declaration } from 'types/entities';

// ISR causado del periodo or IVA a cargo o a favor
export function getInFavorOrAgainst({
  declaration,
  totalKey,
  favorKey,
  unduePaymentKey,
  declarations = [],
}: {
  declaration: Declaration;
  totalKey: string;
  favorKey: string;
  unduePaymentKey: string;
  declarations?: Declaration[];
}) {
  const values: { [key: string]: any } = camelcaseKeys(declaration);
  const valuesForSingleRegime: { [key: string]: any } = camelcaseKeys(
    declarations[0],
  );

  const total = +values[totalKey];
  const favor = valuesForSingleRegime
    ? +valuesForSingleRegime[favorKey]
    : +values[favorKey];

  const unduePayment = +(values[unduePaymentKey] || 0);
  const result = total - Math.min(total, favor);

  return result - Math.min(result, unduePayment);
}

// Total a pagar
export function getTotalToPayOfPeriod(
  declaration: Declaration,
  ivaInFavorOrAgainst: number,
  isrInFavorOrAgainst: number,
) {
  const { totalPayrollWithholdingsToPay, totalLateFees } =
    camelcaseKeys(declaration);
  return (
    Math.max(ivaInFavorOrAgainst, 0) +
    Math.max(isrInFavorOrAgainst, 0) +
    +totalPayrollWithholdingsToPay +
    +(totalLateFees || 0)
  );
}

// Pagos provisionales realizados
export function getAccruedPayment(declaration: Declaration) {
  const { accruedPayment } = camelcaseKeys(declaration);
  return +(accruedPayment || 0);
}

// Retenciones de sueldos y salarios por pagar
export function getTotalPayrollWithholdingsToPay(declaration: Declaration) {
  const { totalPayrollWithholdingsToPay } = camelcaseKeys(declaration);
  return +(totalPayrollWithholdingsToPay || 0);
}

// Compensación aplicada
export function getUnduePaymentUsedOfIsr(declaration: Declaration) {
  const { unduePaymentUsedOfIsr } = camelcaseKeys(declaration);
  return +(unduePaymentUsedOfIsr || 0);
}

// Saldo a favor de años anteriores
export function getIsrInFavor(declaration: Declaration) {
  const { isrInFavor } = camelcaseKeys(declaration);
  return +(isrInFavor || 0);
}

// Resultado de ISR del periodo
export function getIsrTotalRemaining(declaration: Declaration) {
  const { isrTotal } = camelcaseKeys(declaration);
  return +isrTotal || 0;
}

// Resultado de IVA del periodo
export function getIvaTotalRemaining(declaration: Declaration) {
  const { ivaTotal } = camelcaseKeys(declaration);
  return +ivaTotal || 0;
}

// Recargos
export function getTotalLateFees(declaration: Declaration) {
  const { totalLateFees } = camelcaseKeys(declaration);
  return +(totalLateFees || 0);
}

// Saldo a favor de meses anteriores
export function getIvaInFavor(declaration: Declaration) {
  const { ivaInFavor } = camelcaseKeys(declaration);
  return +ivaInFavor;
}

// Pago de lo indebido de ISR
export function getUnduePaymentIsrInFavor(declaration: Declaration) {
  const { unduePaymentIsrInFavor } = camelcaseKeys(declaration);
  return +(unduePaymentIsrInFavor || 0);
}

// Pago de lo indebido de IVA
export function getUnduePaymentIvaInFavor(declaration: Declaration) {
  const { unduePaymentIvaInFavor } = camelcaseKeys(declaration);
  return +(unduePaymentIvaInFavor || 0);
}

// Pérdida fiscal
export function getIsrTaxLoss(declaration: Declaration) {
  const { isrTaxLoss } = camelcaseKeys(declaration);
  return +(isrTaxLoss || 0);
}

// Compensaciones aplicadas
export function getCompensationsApplied(declaration: Declaration) {
  const isrInFavorOrAgainst = getInFavorOrAgainst({
    declaration,
    totalKey: 'isrTotal',
    favorKey: 'isrInFavor',
    unduePaymentKey: 'unduePaymentIsrInFavor',
  });
  const isrInFavor = getIsrInFavor(declaration);
  return Math.min(isrInFavorOrAgainst, isrInFavor);
}
