import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  subscribe,
  subcriptionCancel,
  subcriptionSelect,
  subscriptionPrices,
  subscriptionDiscount,
  fetchSubscriptions,
} from '@redux/actions';
import usePendingTasksStore from 'store/pendingTasksStore';
import { NotificationManager } from '@components/Notifications';
import getCoupon from '@api/getCoupon';
import dayjs from 'dayjs';
import { getDateObjectWithFormat } from '@util/Utils';
import {
  BILLING_PERIOD_QUANTITY,
  DURATION_TO_BILLING_PERIOD,
  PLAN_NAMES,
} from '@constants/subscription';
import subscriptionService from 'shared/services/subscription';
import toFixedString from '@util/toFixedString';
import { useTaxableEntity } from './taxable_entity';
import useGetTaxableEntityCouponsQuery from './useGetTaxableEntityCouponsQuery';

const { BASE_PRICES } = subscriptionService.constants;

export default function useSubscriptions() {
  const { selectedTask } = usePendingTasksStore();
  const dispatch = useDispatch();
  const { removePendingTask } = usePendingTasksStore();

  const subscriptions = useSelector(
    (state) => state.subscriptions,
    shallowEqual,
  );

  const { taxable_entity } = useTaxableEntity();
  const { lastCoupon } = useGetTaxableEntityCouponsQuery(taxable_entity.id);

  const subscription = useMemo(
    () => subscriptions.subscriptions[0],
    [subscriptions.subscriptions],
  );

  const {
    ui: { selected_card },
  } = useSelector((state) => state.paymentMethods, shallowEqual);

  useEffect(() => {
    if (subscriptions?.subscriptions?.length < 1) {
      dispatch(fetchSubscriptions());
    }
  }, [dispatch, subscriptions.subscriptions.length]);

  const handleSelect = (
    _event,
    _subscription,
    employeeCounter,
    receiptCounter,
  ) => {
    dispatch(subscriptionDiscount(0, ''));
    dispatch(subcriptionSelect(_subscription, employeeCounter, receiptCounter));
    dispatch(subscriptionPrices(false));
  };

  const handlePlanSelect = (planId, plans, billingPeriod) => {
    const selectedPlan = plans.find((p) => p.id === planId);
    if (!selectedPlan) {
      NotificationManager.error(
        'Selecciona un plan válido',
        'Error al seleccionar plan',
      );
      return;
    }
    const extraData = {
      assisted_plan: {
        extra: 0,
        extraReceipt: 0,
        serviceDb: 'assisted_plan',
        serviceId: 20,
        employeeServiceId: 21,
        title: 'Plan asistido',
        service: 'Asistido',
        basePrice: BASE_PRICES.assisted_plan,
      },
      personalized_plan: {
        extra: 0,
        extraReceipt: 0,
        serviceDb: 'personalized_plan',
        serviceId: 22,
        employeeServiceId: 21,
        title: 'Plan personalizado',
        service: 'Personalizado',
        basePrice: BASE_PRICES.personalized_plan,
      },
    };
    const selectedExtraData = extraData[planId];
    const selectedPlanData = {
      price: selectedPlan.pricing.price,
      basePrice: selectedExtraData.basePrice,
      extra: selectedExtraData.extra,
      extraReceipt: selectedExtraData.extraReceipt,
      service_id: selectedExtraData.serviceId,
      employee_service_id: selectedExtraData.employeeServiceId,
      service_db: selectedExtraData.serviceDb,
      title: selectedExtraData.title,
      service: selectedExtraData.service,
      billingPeriod,
      durationMoths: BILLING_PERIOD_QUANTITY[selectedPlan.billingPeriod],
    };
    handleSelect(null, selectedPlanData, 0, 30);
  };

  // TODO: rename to applyDiscount
  const handleDiscount = useCallback(
    async (_event, code) => {
      if (!code) {
        return;
      }
      const lowerCaseCoupon = code.toLowerCase();
      try {
        const coupon = await getCoupon(lowerCaseCoupon);
        if (!coupon.active) {
          dispatch(subscriptionDiscount(0, ''));
          NotificationManager.error(
            'El código ingresado no está activo',
            'Descuento no válido',
            6000,
            null,
            null,
          );
          return;
        }
        dispatch(subscriptionDiscount(coupon, lowerCaseCoupon));
        NotificationManager.success(
          'Descuento aplicado',
          'Exito!',
          6000,
          null,
          null,
        );
      } catch {
        dispatch(subscriptionDiscount(0, ''));
        NotificationManager.error(
          'Favor de verificar que el cupón esté bien escrito',
          'Descuento no válido',
          6000,
          null,
          null,
        );
      }
    },
    [dispatch],
  );

  const hidePrices = () => {
    dispatch(subscriptionPrices(true));
  };

  // TODO check if this function is used in other place
  // Submit subscription
  const handlePayment = (subscription_params) => {
    if (selected_card) {
      dispatch(subscribe(subscription_params));
    } else {
      NotificationManager.primary(
        'Selecciona una tarjeta antes de pagar',
        'Upss...',
        6000,
        null,
        null,
      );
    }
  };

  const handleSubscriptionPayment = () => {
    if (!selected_card) {
      NotificationManager.warning(
        'Selecciona una tarjeta antes de pagar',
        'Upss...',
        6000,
        null,
        null,
      );
      return;
    }

    if (!subscriptions.ui?.selected_subscription) {
      NotificationManager.warning(
        'Selecciona un plan antes de pagar',
        'Upss...',
        6000,
        null,
        null,
      );
      return;
    }

    const { subscription: selectedSubscription, discount } =
      subscriptions.ui.selected_subscription;

    const subscriptionParams = {
      subscriptions: [
        {
          service_subscriptions_attributes: [
            {
              service_id: selectedSubscription.service_id,
              quantity: 1,
              duration_months: selectedSubscription.durationMoths,
            },
          ],
          subscription_type: 'contabilidad',
        },
      ],
      payment_way: 'card',
      payment_method_id: selected_card?.id,
      discountCode: discount.code || undefined,
    };

    if (lastCoupon) {
      delete subscriptionParams.discountCode;
    }

    dispatch(
      subscribe(subscriptionParams, () => {
        removePendingTask(selectedTask);
      }),
    );
  };

  const handleCancel = (subscription_id, reasons) => {
    dispatch(subcriptionCancel(subscription_id, reasons));
  };

  const taxableEntityCoupon = useMemo(() => {
    if (!subscription?.taxable_entity_coupon) {
      return {};
    }
    return subscription.taxable_entity_coupon;
  }, [subscription?.taxable_entity_coupon]);

  const nextServiceSubscription = useMemo(() => {
    if (
      !subscription?.next_service_subscriptions ||
      subscription?.next_service_subscriptions?.length === 0
    ) {
      return {};
    }
    const nextServiceSubscriptions = subscription.next_service_subscriptions;

    // Base service
    const nextSubscription = nextServiceSubscriptions.find(
      (service) => service.service.base,
    );

    const validTo = new Date(nextSubscription.valid_to);
    const dateSubscribed = new Date(nextSubscription.date_subscribed);
    const dateSubscribedWithFormat = getDateObjectWithFormat(dateSubscribed);
    const dateValidToWithFormat = getDateObjectWithFormat(validTo);
    const totalOfNextPayment = +nextSubscription.total;
    const totalOfNextPaymentWithoutIva = +toFixedString(
      totalOfNextPayment / 1.16,
    );
    const totalIvaOfNextPayment = totalOfNextPaymentWithoutIva * 0.16;

    // Extra services
    const extraRegimeService = nextServiceSubscriptions.find(
      (service) => service.service.id === 21,
    );
    const extraTaxesService = nextServiceSubscriptions.find(
      (service) => service.service.id === 23,
    );

    const totalOfExtraRegimesNextPayment = extraRegimeService?.total || 0;
    const totalOfExtraTaxesNextPayment = extraTaxesService?.total || 0;

    const extraRegimeQuantity = extraRegimeService?.quantity || 0;

    const totalOfExtraRegimeWithoutIva = +toFixedString(
      totalOfExtraRegimesNextPayment / 1.16,
    );
    const totalOfExtraTaxesWithoutIva = +toFixedString(
      totalOfExtraTaxesNextPayment / 1.16,
    );

    const totalIvaOfExtraRegime = +toFixedString(
      totalOfExtraRegimeWithoutIva * 0.16,
    );
    const totalIvaOfExtraTaxes = +toFixedString(
      totalOfExtraTaxesWithoutIva * 0.16,
    );

    // Totals with extra services and discount
    const totalOfNextPaymentWithExtraServices =
      totalOfNextPayment +
      +totalOfExtraRegimesNextPayment +
      +totalOfExtraTaxesNextPayment;

    const subtotalOfNextPayment =
      totalOfNextPaymentWithoutIva +
      +totalOfExtraRegimeWithoutIva +
      +totalOfExtraTaxesWithoutIva;

    const { totalWithDiscount: totalOfNextPaymentWithDiscount, discountText } =
      subscriptionService.utils.calculatePlanDiscountAndTotal(
        taxableEntityCoupon?.coupon,
        totalOfNextPaymentWithExtraServices,
        nextSubscription.duration_months,
        nextSubscription.service.name,
      );
    const totalOfNextPaymentWithDiscountWithoutIva = +toFixedString(
      totalOfNextPaymentWithDiscount / 1.16,
    );
    const totalIvaOfTotalNextPaymentWithDiscount = +toFixedString(
      totalOfNextPaymentWithDiscount * 0.16,
    );
    const nextDiscount =
      totalOfNextPaymentWithExtraServices - totalOfNextPaymentWithDiscount;
    const nextPrice = +nextSubscription.price;
    const nextPriceWithoutIva = +toFixedString(nextPrice / 1.16);

    const regimePrice = extraRegimeService?.service?.price || 0;
    const taxesPrice = extraTaxesService?.service?.price || 0;

    const nextRegimePriceWithoutIva = +toFixedString(regimePrice / 1.16);
    const nextTaxesPriceWithoutIva = +toFixedString(taxesPrice / 1.16);

    return {
      ...nextSubscription,
      nextPlanName: PLAN_NAMES[nextSubscription.service.name],
      durationMonth: nextSubscription.duration_months,
      billingPeriod:
        DURATION_TO_BILLING_PERIOD[nextSubscription.duration_months],
      validTo,
      dateValidToWithFormat,
      dateSubscribed,
      dateSubscribedWithFormat,
      totalOfNextPayment,
      totalOfNextPaymentWithoutIva,
      totalOfNextPaymentWithDiscount,
      totalOfNextPaymentWithDiscountWithoutIva,
      totalIvaOfTotalNextPaymentWithDiscount,
      totalIvaOfNextPayment,
      nextDiscount,
      nextPrice,
      nextPriceWithoutIva,
      quantity: nextSubscription.quantity,
      discountText,
      totalOfExtraRegimeWithoutIva,
      totalOfExtraTaxesWithoutIva,
      totalIvaOfExtraRegime,
      totalIvaOfExtraTaxes,
      extraRegimeQuantity,
      subtotalOfNextPayment,
      totalOfExtraRegimesNextPayment,
      totalOfExtraTaxesNextPayment,
      nextRegimePriceWithoutIva,
      nextTaxesPriceWithoutIva,
    };
  }, [subscription?.next_service_subscriptions, taxableEntityCoupon?.coupon]);

  const currentServiceSubscription = useMemo(() => {
    if (
      !subscription?.current_service_subscriptions ||
      subscription?.current_service_subscriptions?.length === 0
    ) {
      return {};
    }
    const currentSubscriptions = subscription.current_service_subscriptions;

    // Base service
    const currentSubscription = currentSubscriptions.find(
      (service) => service.service.base,
    );
    const totalEmployees = currentSubscriptions
      .filter(({ service }) => service.id === 16 || service.id === 6)
      .reduce((total, obj) => obj.quantity + total, 0);

    const dateValidTo = dayjs(currentSubscription.valid_to)
      .add(1, 'day')
      .toDate();
    const dateSubscribed = new Date(currentSubscription.date_subscribed);

    const dateValidToWithFormat = getDateObjectWithFormat(dateValidTo);
    const dateSubscribedWithFormat = getDateObjectWithFormat(dateSubscribed);

    const totalTransactions =
      currentSubscriptions
        .filter(({ service }) => !service.base)
        .reduce((total, obj) => obj.quantity + total, 0) - totalEmployees;

    const isActive = subscription.status !== 'cancellation_in_progress';
    const hasTransactions = totalTransactions > 0;

    const totalPayment = +currentSubscription.total;
    const totalPaymentWithoutIva = +toFixedString(totalPayment * 0.84);

    // Extra services
    const extraRegimeService = currentSubscriptions.find(
      (service) => service.service.id === 21,
    );
    const extraTaxesService = currentSubscriptions.find(
      (service) => service.service.id === 23,
    );

    const totalOfExtraRegimesPayment = extraRegimeService?.total || 0;
    const totalOfExtraTaxesPayment = extraTaxesService?.total || 0;

    const extraRegimeQuantity = extraRegimeService?.quantity || 0;

    const totalOfExtraRegimeWithoutIva = +toFixedString(
      totalOfExtraRegimesPayment / 1.16,
    );
    const totalOfExtraTaxesWithoutIva = +toFixedString(
      totalOfExtraTaxesPayment / 1.16,
    );

    const totalIvaOfExtraRegime = +toFixedString(
      totalOfExtraRegimeWithoutIva * 0.16,
    );
    const totalIvaOfExtraTaxes = +toFixedString(
      totalOfExtraTaxesWithoutIva * 0.16,
    );

    // Totals with extra services
    const totalPaymentWithExtraServices =
      totalPayment + +totalOfExtraRegimesPayment + +totalOfExtraTaxesPayment;

    const subtotalPayment =
      totalPaymentWithoutIva +
      totalOfExtraRegimeWithoutIva +
      totalOfExtraTaxesWithoutIva;

    const { totalWithDiscount: totalPaymentWithDiscount, discountText } =
      subscriptionService.utils.calculatePlanDiscountAndTotal(
        taxableEntityCoupon?.coupon,
        totalPaymentWithExtraServices,
        currentSubscription.duration_months,
        currentSubscription.service.name,
      );
    const totalPaymentWithDiscountWithoutIva = +toFixedString(
      totalPaymentWithDiscount * 0.84,
    );
    const totalIvaOfPaymentWithDiscount = +toFixedString(
      totalPaymentWithDiscount * 0.16,
    );
    const discount = totalPayment - totalPaymentWithDiscount;
    const price = +currentSubscription.price;
    const priceWithoutIva = +toFixedString(price * 0.84);

    const regimePrice = extraRegimeService?.service?.price || 0;
    const taxesPrice = extraTaxesService?.service?.price || 0;

    const extraRegimePriceWithoutIva = +toFixedString(regimePrice / 1.16);
    const extraTaxesPriceWithoutIva = +toFixedString(taxesPrice / 1.16);

    return {
      ...currentSubscription,
      isActive,
      planName: PLAN_NAMES[currentSubscription.service.name],
      durationMonth: currentSubscription.duration_months,
      billingPeriod:
        DURATION_TO_BILLING_PERIOD[currentSubscription.duration_months],
      totalEmployees,
      totalTransactions,
      hasTransactions,
      totalOfMovements: totalTransactions + 30,
      dateValidToWithFormat,
      dateSubscribedWithFormat,
      quantity: currentSubscription.quantity,
      price,
      priceWithoutIva,
      totalPaymentWithoutIva,
      totalPaymentWithDiscountWithoutIva,
      totalIvaOfPaymentWithDiscount,
      discount,
      totalPaymentWithDiscount,
      discountText,
      totalOfExtraRegimeWithoutIva,
      totalOfExtraTaxesWithoutIva,
      totalIvaOfExtraRegime,
      totalIvaOfExtraTaxes,
      extraRegimeQuantity,
      subtotalPayment,
      totalOfExtraRegimesPayment,
      totalOfExtraTaxesPayment,
      extraRegimePriceWithoutIva,
      extraTaxesPriceWithoutIva,
    };
  }, [
    subscription?.current_service_subscriptions,
    subscription?.status,
    taxableEntityCoupon?.coupon,
  ]);

  return {
    ...subscriptions,
    hidePrices,
    handleSelect,
    handlePayment,
    handleSubscriptionPayment,
    handleCancel,
    handleDiscount,
    nextServiceSubscription,
    currentServiceSubscription,
    handlePlanSelect,
  };
}
