import { call } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import * as Sentry from '@sentry/react';
import api from '@api';
import { setUserDataToOpenReplay } from '@util/OpenReplay';
import { identifySegment } from '@util/Segment';
import gtmEvent from '@util/gtmEvent';
import canSendTrack from '@util/canSendTrack';
import { UTMsKey } from '@hooks/useUTMs';
import {
  load as loadIntercom,
  boot as bootIntercom,
  formatInfo,
} from '@util/Intercom';
import { registerUserSuccess, registerUserFailure } from '../actions';

function sendUTMs(email) {
  try {
    if (canSendTrack() && localStorage && UTMsKey in localStorage) {
      const utmObject = JSON.parse(localStorage.getItem(UTMsKey));
      const payload = {
        email,
      };
      Object.assign(
        payload,
        ...utmObject.UTMs.map((utm) => ({
          [utm.key]: utm.value,
        })),
      );
      window.analytics.track('UTMs Register', payload);
    }
  } catch (e) {
    if (Sentry?.captureException) {
      console.error(e);
      Sentry.captureException(e);
    }
  }
}

export default function* ({
  payload: { navigate, user, captchaToken, featureClient, callback },
}) {
  try {
    const {
      first_name,
      last_name,
      email,
      password,
      password_confirmation,
      phone_number,
    } = user;
    const payload = {
      user: {
        first_name,
        last_name,
        email,
        password,
        password_confirmation,
        phone_number,
      },
      captcha_token: captchaToken,
    };
    const rc =
      localStorage.getItem('rc') ||
      Cookies.get('rc', {
        domain: '.konta.com',
      });
    if (rc) {
      payload.rc = rc;
    }
    const invitationToken =
      localStorage.getItem('invitation-token') ||
      Cookies.get('invitation-token', { domain: '.konta.com' });
    if (invitationToken) {
      payload.invitation_token = invitationToken;
    }
    const response = yield call(api.post, '/', payload);
    const { access_token, id, taxable_entity } = response.data;
    localStorage.setItem('user_id', access_token);
    if (callback) callback(response.data);
    yield call(StatusHandler, {
      response,
      success: registerUserSuccess,
      failure: registerUserFailure,
      options: { silent: true },
    });
    navigate('/');
    // Google tag manager event submit
    gtmEvent('AppEvent', 'registerSuccess');
    // Sentry data set scope
    Sentry.configureScope((scope) => {
      scope.setUser({
        id,
        email,
        username: first_name,
        phone_number,
      });
    });
    const userCreated = response.data;
    setUserDataToOpenReplay({
      user: userCreated,
      taxable_entity,
    });
    const featureFlags = yield featureClient.identify({
      kind: 'user',
      key: id,
      email,
      firstName: first_name,
      hasSubscription: taxable_entity?.status !== 'demo',
    });
    identifySegment(userCreated.id, featureFlags);
    sendUTMs(email);
    yield loadIntercom();
    yield bootIntercom(formatInfo({ user }));
  } catch (error) {
    const invalidToken =
      error.response.status === 401 &&
      'Recargue la página e intente de nuevo, si el problema persiste contacte a soporte técnico.';

    yield call(ErrorHandler, {
      error,
      title: 'Error al registrar al usuario',
      message: invalidToken,
      action: registerUserFailure,
      showBodyErrors: true,
    });
  }
}
