import { styled } from '@konta/ui';

const MetricGroup = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  alignSelf: 'stretch',
  flexWrap: 'wrap',
  flex: 1,
  flexDirection: 'column',
  '@md': {
    flexDirection: 'row',
  },
});

export default MetricGroup;
