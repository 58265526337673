import type { ColumnFiltersState } from '@tanstack/react-table';
import type { PaginationParams } from 'types/pagination';
import ransackTableParams from 'shared/util/ransackTableParams';
import useCfdiStore from 'store/cfdisStore';

const format = 'YYYY-MM-DD';
export default function getRansackFilters(
  filters: ColumnFiltersState,
): PaginationParams {
  const { dateFilter, selectedTab } = useCfdiStore.getState();
  const isEmitted = selectedTab === 'emitted';
  const filterMap: Record<string, string> = {
    rfc: isEmitted ? 'q[receiver_rfc_i_cont]' : 'q[issuer_rfc_i_cont]',
    legalName: isEmitted
      ? 'q[receiver_legal_name_i_cont]'
      : 'q[issuer_legal_name_i_cont]',
    type: 'q[cfdi_type_eq]',
    status: 'q[status_eq]',
    paymentMethod: 'q[payment_method_eq]',
    invoice_type: 'q[invoice_type_eq]',
    folio_fiscal: 'q[folio_fiscal_i_cont]',
  };

  const defaultFilters: PaginationParams = {
    'q[is_emited_true]': isEmitted ? 1 : 0,
    'q[date_issued_gteq_all]': dateFilter.startOf('month').format(format),
    'q[date_issued_lteq_all]': dateFilter.endOf('month').format(format),
  };

  return ransackTableParams(filters, {
    defaultFilters,
    dict: filterMap,
  });
}
