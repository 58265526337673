import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalFooter } from 'reactstrap';
import { Button, ModalContent, ModalHeader } from '@konta/ui';
import CloseIcon from 'mdi-react/CloseIcon';

export default function ConfirmModal({
  acceptText,
  cancelText,
  children,
  onAccept,
  onCancel,
  onClose,
  open,
  title,
  loading,
  withDisabledLoading,
  size = 'md',
  withBlur = false,
  centered = false,
  withFooter = true,
  isAcceptBtnEnabled = true,
  spaceBetweenFooter = false,
}) {
  return (
    <Modal
      centered={centered}
      size={size}
      isOpen={open}
      toggle={onClose}
      {...(withBlur && {
        wrapClassName: 'modal-backdrop-blur',
      })}
    >
      <ModalHeader
        divided
        css={{
          fontWeight: '500',
          fontSize: '18px',
          color: '$gray900',
          mr: '0!important',
          justifyContent: 'space-between',
          mb: '$8',
        }}
      >
        {title}
        <Button icon variant="text" onClick={onClose}>
          <CloseIcon />
        </Button>
      </ModalHeader>
      <ModalContent>{children}</ModalContent>
      {withFooter && (
        <ModalFooter
          className={`d-flex ${
            spaceBetweenFooter
              ? 'justify-content-between'
              : 'justify-content-end'
          }`}
        >
          {!!cancelText && !!onCancel && (
            <Button
              loading={loading}
              variant="outlined"
              data-testid="cancel"
              onClick={onCancel}
              {...(withDisabledLoading && { disabled: loading })}
            >
              {cancelText}
            </Button>
          )}
          <Button
            loading={loading}
            color="primary"
            data-testid="accept"
            onClick={onAccept}
            disabled={!isAcceptBtnEnabled}
            {...(withDisabledLoading && { disabled: loading })}
          >
            {acceptText}
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
}

ConfirmModal.propTypes = {
  acceptText: PropTypes.string,
  cancelText: PropTypes.string,
  children: PropTypes.node,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  loading: PropTypes.bool,
  withDisabledLoading: PropTypes.bool,
};

ConfirmModal.defaultProps = {
  acceptText: 'Aceptar',
  cancelText: 'Cancelar',
  children: null,
  onAccept: () => {},
  onCancel: null,
  onClose: () => {},
  open: false,
  title: 'Confirmación',
  loading: false,
  withDisabledLoading: false,
};
