import camelcaseKeys from 'camelcase-keys';
import type { DeclarationIncome } from 'types/entities';

export default function getTotalsFromDeclarationIncome(
  declarationIncome: DeclarationIncome,
) {
  const {
    total,
    totalIvaBases,
    totalIvaBasesOfCfdis,
    totalIvaExemptBasesOfCfdis,
    totalZeroBasesOfCfdis,
    totalIvaOfCfdis,
    totalUnbilled,
    totalIvaBasesUnbilled,
    totalRetainedIvaOfCfdis,
    totalRetainedIsrOfCfdis,
    totalZeroBases,
    totalExemptBases,
    totalZeroBasesUnbilled,
    totalExemptBasesUnbilled,
    totalWithIvaOfManualInput,
    totalWithIva16OfManualInput,
    totalWithIva8OfManualInput,
    totalIvaBase8Unbilled,
    totalIvaBase16Unbilled,
    totalIva8Bases,
    totalIva16Bases,
    totalIva,
    totalRetainedIsr,
    totalRetainedIva,
    totalRetainedIsr8OfCfdis,
    totalRetainedIva8OfCfdis,
    totalRetainedIsr16OfCfdis,
    totalRetainedIva16OfCfdis,
    sumIvaBasesOfConstancies,
    sumExemptBasesOfConstancies,
    extraIva,
    extraZeroBases,
    sumRetainedIsrOfConstancies,
    taxesWithheldOfPayroll,
    employmentSubsidyOfPayroll,
    sumIvaBase16OfManualInput,
    sumIvaBase8OfManualInput,
    totalZeroBasesOfManualInput,
    totalExemptBasesOfManualInput,
    sumIvaBasesOfEgress,
    accruedIsrRetention,
    sumRetainedIvaOfConstancies,
    extraIvaBases16,
    extraIvaBases8,
    extraExemptBases,
  } = camelcaseKeys(declarationIncome);

  const totalBasesOfCfdis =
    +totalIvaBasesOfCfdis +
    +totalIvaExemptBasesOfCfdis +
    +totalZeroBasesOfCfdis;

  const totalTaxableBasesOfCfdis = +totalIvaBasesOfCfdis;

  const totalIvaUnbilled =
    +totalIvaBase8Unbilled * 0.08 + +totalIvaBase16Unbilled * 0.16;
  const totalTaxableUnbilled =
    +totalIvaBase8Unbilled * 1.08 + +totalIvaBase16Unbilled * 1.16;

  const totalTaxes = +totalIva8Bases * 0.08 + +totalIva16Bases * 0.16;

  const totalRetainedOfCfdis =
    +totalRetainedIvaOfCfdis + +totalRetainedIsrOfCfdis;

  const totalOfPublicInvoice =
    totalTaxableUnbilled + +totalExemptBasesUnbilled + +totalZeroBasesUnbilled;
  const subTotalOfPublicInvoice = +totalUnbilled;

  const totalRetentions = +totalRetainedIsr + +totalRetainedIva;
  const totalIVA8 =
    +totalIva8Bases * 1.08 -
    +totalRetainedIsr8OfCfdis -
    +totalRetainedIva8OfCfdis;
  const totalIVA16 =
    +totalIva16Bases * 1.16 -
    +totalRetainedIsr16OfCfdis -
    +totalRetainedIva16OfCfdis;

  const grandTotalBases = +totalIvaBases + +totalExemptBases + +totalZeroBases;
  const grandTotal = grandTotalBases + totalTaxes;

  return {
    totalWithIvaOfManualInput: +totalWithIvaOfManualInput,
    totalZeroBasesOfManualInput: +totalZeroBasesOfManualInput + +extraZeroBases,
    totalExemptBasesOfManualInput:
      +totalExemptBasesOfManualInput + +extraExemptBases,
    totalWithIva16OfManualInput: +totalWithIva16OfManualInput || 0,
    totalWithIva8OfManualInput: +totalWithIva8OfManualInput || 0,
    totalBases: +totalIvaBases,
    totalIvaBases: +totalIvaBases,
    totalIva: +totalIva,
    total: +total,
    totalExemptBases: +totalExemptBases,
    totalZeroBases: +totalZeroBases,
    totalZeroBasesUnbilled: +totalZeroBasesUnbilled,
    totalExemptBasesUnbilled: +totalExemptBasesUnbilled,
    totalBasesWithIva: +total + +totalIva,
    totalBasesOfCfdis,
    totalTaxableBasesOfCfdis,
    totalIvaBasesOfCfdis: +totalIvaBasesOfCfdis,
    totalIvaExemptBasesOfCfdis: +totalIvaExemptBasesOfCfdis,
    totalZeroBasesOfCfdis: +totalZeroBasesOfCfdis,
    totalIvaOfCfdis: +totalIvaOfCfdis,
    totalRetainedOfCfdis,
    totalBasesWithIvaOfCfdis:
      +totalBasesOfCfdis +
      +totalIvaOfCfdis -
      +totalRetainedIvaOfCfdis -
      +totalRetainedIsrOfCfdis,
    totalUnbilled: +totalUnbilled,
    totalIvaBasesUnbilled: +totalIvaBasesUnbilled,
    totalIvaUnbilled,
    totalBasesWithIvaUnbilled: +totalUnbilled + +totalIvaUnbilled,
    totalRetainedIvaOfCfdis: +totalRetainedIvaOfCfdis,
    totalRetainedIsrOfCfdis: +totalRetainedIsrOfCfdis,
    totalIvaBase8Unbilled: +totalIvaBase8Unbilled,
    totalIvaBase16Unbilled: +totalIvaBase16Unbilled,
    totalIva8Bases: +totalIva8Bases,
    totalIva16Bases: +totalIva16Bases,
    totalOfPublicInvoice,
    subTotalOfPublicInvoice,
    totalTaxes,
    totalRetentions,
    totalIVA8,
    totalIVA16,
    grandTotalBases,
    grandTotal,
    sumIvaBasesOfConstancies: +sumIvaBasesOfConstancies,
    sumExemptBasesOfConstancies: +sumExemptBasesOfConstancies,
    extraIva: +extraIva,
    extraZeroBases: +extraZeroBases,
    totalRetainedIva: +totalRetainedIva,
    sumRetainedIsrOfConstancies: +sumRetainedIsrOfConstancies,
    taxesWithheldOfPayroll: +taxesWithheldOfPayroll,
    employmentSubsidyOfPayroll: +employmentSubsidyOfPayroll,
    sumIvaBase16OfManualInput: +sumIvaBase16OfManualInput + +extraIvaBases16,
    sumIvaBase8OfManualInput: +sumIvaBase8OfManualInput + +extraIvaBases8,
    sumIvaBasesOfEgress: +sumIvaBasesOfEgress,
    accruedIsrRetention: +accruedIsrRetention,
    totalRetainedIsr: +totalRetainedIsr,
    sumRetainedIvaOfConstancies: +sumRetainedIvaOfConstancies,
    extraIvaBases16: +extraIvaBases16,
  };
}
