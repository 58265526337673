import { CSS, styled } from '@konta/ui';

const flexColumBaseCss: CSS = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
};
export const MetricItem = styled('div', {
  display: 'flex',
  flexDirection: 'column-reverse',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  justifyContent: 'space-between',
  padding: '$20',
  isolation: 'isolate',
  background: '$white',
  border: '1px solid $gray200',
  borderRadius: '$m',
  minWidth: '350px',
  flex: 1,
  '@sm': {
    flexDirection: 'row',
  },
});
export const MetricData = styled('div', {
  ...flexColumBaseCss,
  flex: 1,
});
export const MetricGraph = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  width: '100%',
  maxHeight: '220px',
  marginTop: '0',
  marginBottom: '0',
  '@sm': {
    marginTop: '-15px',
    marginBottom: '-30px',
    width: '150px',
    maxHeight: '130px',
  },
});
export const MetricItemContent = styled('div', {
  flex: '0',
  alignSelf: 'stretch',
  ...flexColumBaseCss,
  p: '$0',
});
export const MetricItemHeader = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  alignSelf: 'stretch',
  p: '$0',
  gap: '$6',
  variants: {
    start: {
      true: {
        alignItems: 'start',
      },
    },
    between: {
      true: {
        justifyContent: 'space-between',
      },
    },
  },
});
export const ChipContainer = styled('div', {
  flex: '0',
  alignSelf: 'stretch',
  ...flexColumBaseCss,
  padding: '3px 0',
});
