import { ReactNode } from 'react';
import CloseIcon from 'mdi-react/CloseIcon';
import { Button } from '@konta/ui';
import { useNavigate } from 'react-router-dom';
import {
  CentralContent,
  Content,
  ContentWrapper,
  LeftSidebar,
  Main,
  TopBar,
  Wrapper,
} from './styled';

export interface FullContentProps {
  content: ReactNode;
  topBar?: ReactNode;
  leftSidebar?: ReactNode;
  withCloseButton?: boolean;
  onClose?: () => void;
}

export default function FullContent({
  content,
  leftSidebar,
  topBar,
  withCloseButton = true,
  onClose,
}: FullContentProps) {
  const navigate = useNavigate();
  const handleClose = () => {
    if (onClose) {
      onClose();
      return;
    }
    navigate(-1);
  };

  return (
    <Wrapper>
      <Main>
        {topBar && (
          <TopBar>
            {withCloseButton && (
              <Button icon variant="text">
                <CloseIcon onClick={handleClose} />
              </Button>
            )}
            {topBar}
          </TopBar>
        )}
        <ContentWrapper>
          <Content>
            <CentralContent>{content}</CentralContent>
            {leftSidebar && <LeftSidebar>{leftSidebar}</LeftSidebar>}
          </Content>
        </ContentWrapper>
      </Main>
    </Wrapper>
  );
}
