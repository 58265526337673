import { SVGProps } from 'react';
import {
  Users01LineIcon,
  Speedometer02LineIcon,
  PresentationChart02LineIcon,
  UserDown01LineIcon,
  File05LineIcon,
  BrowserLineIcon,
} from '@konta/icons';
import type { Permission } from './permissions';

export type Module = {
  id: string;
  icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  label: string;
  to: string;
  counter?: number | null;
  title: string;
  subMenus?: Module[];
  permissions?: Permission;
};

const modules: Module[] = [
  {
    id: 'home',
    icon: Speedometer02LineIcon,
    label: 'Panel de información',
    to: '/dashboard',
    counter: null,
    title: 'Panel de información',
  },
  {
    id: 'customers',
    icon: Users01LineIcon,
    label: 'Clientes',
    to: '/customers',
    counter: null,
    title: 'Clientes',
  },
  {
    id: 'declarations',
    icon: File05LineIcon,
    label: 'Declaraciones',
    to: '',
    counter: null,
    title: 'Declaraciones',
    subMenus: [
      {
        id: 'entriesWithoutClassification',
        label: 'Entradas sin clasificar',
        to: '/declarations/uncategorized-taxable-entities',
        title: 'Taxable entities con entradas sin clasificar',
      },
    ],
  },
  {
    id: 'onboardings',
    icon: UserDown01LineIcon,
    label: 'Onboardings',
    to: '',
    counter: null,
    title: 'Onboardings',
    subMenus: [
      {
        id: 'myOnboardings',
        label: 'Mis onboardings',
        to: '/my-onboardings',
        title: 'Mis onboardings',
      },
      {
        id: 'unassignedOnboardings',
        label: 'Onboarding sin asignar',
        to: '/unassigned-onboardings',
        title: 'Onboarding sin asignar',
      },
      {
        id: 'allOnboardings',
        label: 'Todos los onboardings',
        to: '/onboardings',
        title: 'Todos los onboardings',
      },
    ],
  },
  {
    id: 'reports',
    icon: PresentationChart02LineIcon,
    label: 'Reportes',
    to: '/reports',
    counter: null,
    title: 'Reportes',
  },
  {
    id: 'retool',
    icon: BrowserLineIcon,
    label: 'Retool',
    to: '/retool',
    counter: null,
    title: 'Retool',
  },
];

export type TitlesAndPaths = {
  title: string;
  to: string;
};

const titlesAndPaths: TitlesAndPaths[] = modules
  .map(({ title, to, subMenus }) => {
    if (subMenus) {
      return subMenus.map(({ title: subMenuTitle, to: subMenuTo }) => ({
        title: subMenuTitle,
        to: subMenuTo,
      }));
    }
    return { title, to };
  })
  .flat();

export type MenuModules = {
  modules: Module[];
  titlesAndPaths: TitlesAndPaths[];
};

export default { modules, titlesAndPaths };
