import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row } from 'reactstrap';
import { Eye2Icon } from '@konta/icons';
import { connect, useDispatch } from 'react-redux';
import {
  workflowSetTransition,
  addressFetch,
  uploadXmlCfdi,
} from '@redux/actions';
import { Colxx, Emoji, ButtonConfirmModal } from '@components';
import WorkflowLayout from '@components/WorkflowLayout';
import PrevPhaseButton from '@components/PrevPhaseButton';
import WorkflowHeader from '@components/WorkflowHeader';
import { AddPublicInvoice } from './addPublicInvoice/PublicInvoiceUpload';
import MissingPublicInvoicePreview from '../MissingPublicInvoicePreview';
// eslint-disable-next-line import/extensions
import NotCsdUploadInvoiceNextPhase from '../NotCsdUploadInvoiceNextPhase';
import HelpCenterLink from '../../../../@components/HelpCenterLink';

function NotCsdUploadInvoice({ workflow, address }) {
  const dispatch = useDispatch();
  const [filesState, setFilesState] = useState({
    files: [],
    validFiles: false,
  });

  useEffect(() => {
    if (!address) {
      dispatch(addressFetch());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const onNextClick = () => {
    dispatch(workflowSetTransition('phase-front'));
    const body = new FormData();

    body.append(
      'declaration_entry[declaration_id]',
      workflow.declarations[0].id,
    );

    filesState.files.forEach((file) => {
      body.append('declaration_entry[source_attributes][xml]', file);
    });

    body.append('declaration_entry[accounting_status]', 'is_deductible');
    body.append('declaration_entry[source_type]', 'Cfdi');
    body.append(
      'declaration_entry[accounting_date]',
      workflow.declarations[0].start_date,
    );
    body.append(
      'declaration_entry[taxable_entity_id]',
      workflow.taxable_entity_id,
    );

    dispatch(
      uploadXmlCfdi({ workflow_id: workflow.id, body, nextPhase: true }),
    );
  };

  const eventAddHandlers = {
    addedfile: (file) => {
      setFilesState({
        ...filesState,
        files: [...filesState.files, file],
        validFiles: true,
      });
    },
    removedfile: (file) => {
      const { files } = filesState;
      const newFileArray = files.filter(
        (item) => item.name === file.name && item.size === file.size,
      );
      setFilesState({
        ...filesState,
        files: newFileArray,
        validFiles: !!files.length,
      });
    },
  };

  return (
    <WorkflowLayout
      header={
        <WorkflowHeader
          title="Creación de factura manual"
          description="Si está en esta sección del proceso es porque aún no ha subido sus Sellos Digitales (CSD), por lo que debemos hacer una factura al Público en general. Debemos arreglarlo para asegurarnos que la declaración este correcta."
        />
      }
      actions={
        <>
          <PrevPhaseButton />
          <HelpCenterLink articleId="what_is_csd">
            <Emoji symbol="🚀" label="rocket" className="mr-2" />
            <span className="clickable-text-color">
              ¿Quieres automatizar esta factura? click aquí
            </span>
          </HelpCenterLink>
          <NotCsdUploadInvoiceNextPhase
            isNextBtnDisabled={filesState.files.length === 0}
            onNextBtnClick={onNextClick}
          />
        </>
      }
    >
      <div className="d-flex align-items-center w-100">
        <div className="w-100">
          <Row className="mb-4">
            <Colxx xxs="12" md="12">
              <div className="w-80 w-sm-100">
                <h1 className="mb-2 pb-0">
                  <Emoji symbol="⚠️" label="warning" /> Aún no ha subido sus
                  sellos para emitir su factura, tendrá que ir al SAT y emitir
                  una factura al público en general.
                </h1>
                <p className="text-muted mb-0">
                  Si tiene dudas de cómo realizar la factura, tenemos un
                  artículo explicando cómo hacerla correctamente.{' '}
                  <span>
                    <HelpCenterLink articleId="public_invoice">
                      Click aquí
                    </HelpCenterLink>{' '}
                    o igual tenemos un video{' '}
                    <HelpCenterLink articleId="public_invoice">
                      aquí
                    </HelpCenterLink>
                    .
                  </span>
                </p>
              </div>
            </Colxx>
          </Row>
          <Row className="mb-4">
            <Colxx xxs="12" md="3" className="d-flex justify-content-start">
              <ButtonConfirmModal
                className="w-100"
                header="Factura ejemplo"
                bodyClassName="d-flex"
                buttonColor="primary"
                buttonClassName="new-btn mr-3"
                handleSubmit={() => {}}
                confirmButton="OK"
                buttonLabel="Ver factura ejemplo"
                isButtonDisabled={false}
                outline
                modalSize="lg"
                lineIcon={Eye2Icon}
              >
                <MissingPublicInvoicePreview />
              </ButtonConfirmModal>
            </Colxx>
          </Row>
          <Row className="mb-4">
            <Colxx sm="12">
              <h2 className="mb-4">
                El siguiente paso sería subir su factura en formato(.xml) aquí.{' '}
                <Emoji symbol="👇🏼" label="point-down" className="mr-2" />
              </h2>
              <AddPublicInvoice events={eventAddHandlers} />
            </Colxx>
          </Row>
        </div>
      </div>
    </WorkflowLayout>
  );
}

const mapStateToProps = ({ workflows, addresses }) => {
  const {
    selected_workflow: { workflow, loading: wloading },
  } = workflows;
  const { address } = addresses;
  return { workflow, wloading, address };
};

export default connect(mapStateToProps)(NotCsdUploadInvoice);
