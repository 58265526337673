import { call, put } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import * as Sentry from '@sentry/react';
import { getBase64FromUrl } from '@util/Utils';
import api from '@api';
import { setUserDataToOpenReplay } from '@util/OpenReplay';
import { identifySegment } from '@util/Segment';
import { getAccessToken, setSession } from '@util/Authentication';
import {
  load as loadIntercom,
  boot as bootIntercom,
  formatInfo,
} from '@util/Intercom';
import { fetchUserSuccess, fetchUserFailure, stateReset } from '../actions';

export default function* ({ payload: { featureClient } }) {
  try {
    const response = yield call(api.get, '/sign_in', {
      headers: { Authorization: `${getAccessToken()}` },
    });
    if (response.data.taxable_entity.logo_url) {
      response.data.taxable_entity.logo_url = yield getBase64FromUrl(
        response.data.taxable_entity.logo_url,
      );
    }

    const {
      access_token,
      email,
      phone_number,
      id,
      first_name,
      taxable_entity,
    } = response.data;
    setSession(access_token);
    yield call(StatusHandler, {
      response,
      success: fetchUserSuccess,
      failure: fetchUserFailure,
      options: { silent: true },
    });
    // Sentry data set scope
    Sentry.configureScope((scope) => {
      scope.setUser({
        id,
        email,
        username: first_name,
        phone_number,
      });
    });
    const user = response.data;
    setUserDataToOpenReplay({
      user,
      taxable_entity,
    });
    const featureFlags = yield featureClient.identify({
      kind: 'user',
      key: id,
      email,
      firstName: first_name,
      hasSubscription: taxable_entity?.status !== 'demo',
    });
    identifySegment(user.id, featureFlags);
    yield loadIntercom();
    yield bootIntercom(formatInfo({ user }));
  } catch (error) {
    localStorage.removeItem('user_id');
    yield put(stateReset());
    yield call(ErrorHandler, {
      error,
      action: fetchUserFailure,
    });
  }
}
