import { useState, useEffect, Fragment, useCallback } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { ChevronRightCrFrOutlineIcon } from '@konta/icons';
import { config, Flex, styled, useMediaQuery } from '@konta/ui';
import { useAppContext } from 'backoffice/context/AppContext';
import useSignIn from '../../hooks/useSignIn';

const SidebarNavMenuItem = styled('span', {
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  height: '40px',
  borderRadius: '8px',
  padding: '8px',
  borderBottom: '1px solid rgb(255, 255, 255)',
  fontStyle: 'normal',
  lineHeight: 'normal',
  textAlign: 'left',
  color: 'rgb(60, 65, 73)',
  '&:hover': {
    backgroundColor: '$primary50',
    color: '$primary700',
  },
  svg: {
    width: '$24',
    height: '$24',
    path: {
      fill: 'transparent',
      stroke: '$gray500',
    },
  },
  '[data-arrow="true"]': {
    transform: 'rotate(0)',
    marginRight: 0,
    path: {
      fill: '$gray500',
    },
  },
  variants: {
    active: {
      true: {
        backgroundColor: '$primary50',
        color: '$primary700',
      },
    },
    hasHover: {
      true: {
        svg: {
          width: '$24',
          height: '$24',
        },
      },
    },
    collapsed: {
      true: {
        '[data-arrow="true"]': {
          transition: 'transform 200ms',
          transform: 'rotate(90deg)',
        },
      },
    },
  },
});
const NavLinkContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});
const SidebarNavLink = styled('div', {
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginLeft: '$16',
  variants: {
    hasHover: {
      false: {
        display: 'none',
      },
    },
  },
});
const StyledNavLink = styled(NavLink, {
  py: '$8',
  paddingLeft: '$24',
  borderRadius: '$m',
  '&:hover': {
    backgroundColor: '$primary50',
    color: '$primary700',
  },
  variants: {
    active: {
      true: {
        backgroundColor: '$primary50',
        color: '$primary700',
      },
    },
  },
});
const Collapse = styled('div', {
  display: 'none',
  variants: {
    isOpen: {
      true: {
        display: 'block',
      },
    },
  },
});

interface SidebarNavMenuProps {
  toggleMobileMenuOpen: () => void;
  hasHover?: boolean;
  toggleMenuOpen: boolean;
  setToggleMenuOpen: (toggleMenuOpen: boolean) => void;
}

export default function SidebarNavMenu({
  toggleMobileMenuOpen,
  hasHover,
  toggleMenuOpen,
  setToggleMenuOpen,
}: SidebarNavMenuProps) {
  const { menu } = useAppContext();
  const location = useLocation();
  const { user } = useSignIn({ enabled: false });
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery(config.media['<lg']);
  const [collapsedMenu, setCollapsedMenu] = useState<string[]>([]);

  const toggleCollapsedMenu = useCallback(
    (id: string) => {
      if (collapsedMenu.includes(id)) {
        setCollapsedMenu(collapsedMenu.filter((item) => item !== id));
        return;
      }
      setCollapsedMenu([...collapsedMenu, id]);
    },
    [collapsedMenu],
  );

  useEffect(() => {
    if (!hasHover) {
      setCollapsedMenu([]);
    }
  }, [hasHover]);

  useEffect(() => {
    menu.modules.forEach((sidebarMenuModule) => {
      if (location.pathname.includes(sidebarMenuModule.id)) {
        toggleCollapsedMenu(sidebarMenuModule.id);
      }
    });
  }, []);

  return (
    <Flex column>
      {menu.modules?.map((sidebarMenuModule) => {
        const hasSubMenus =
          sidebarMenuModule?.subMenus?.length &&
          sidebarMenuModule.subMenus.length > 0;
        const isCollapsedOpen = collapsedMenu.includes(sidebarMenuModule.id);
        const isItemActive =
          !!sidebarMenuModule.to &&
          location.pathname.startsWith(sidebarMenuModule.to);

        return (
          <Fragment key={sidebarMenuModule.id}>
            <SidebarNavMenuItem
              active={isItemActive}
              hasHover={hasHover}
              collapsed={isCollapsedOpen}
              onClick={() => {
                if (hasSubMenus) {
                  toggleCollapsedMenu(sidebarMenuModule.id);
                  setToggleMenuOpen(true);
                  return;
                }
                if (isTabletOrMobile) {
                  toggleMobileMenuOpen();
                }
                if (sidebarMenuModule.to) {
                  navigate(sidebarMenuModule.to);
                }
              }}
            >
              {sidebarMenuModule.icon && <sidebarMenuModule.icon data-icon />}
              <SidebarNavLink hasHover={hasHover}>
                {sidebarMenuModule.label}
              </SidebarNavLink>

              {hasHover && hasSubMenus && (
                <ChevronRightCrFrOutlineIcon data-arrow />
              )}
            </SidebarNavMenuItem>
            {hasSubMenus && toggleMenuOpen && (
              <Collapse isOpen={isCollapsedOpen}>
                <NavLinkContainer>
                  {sidebarMenuModule?.subMenus?.map?.((subMenu) => {
                    const userPermissions = subMenu?.permissions?.users;
                    const keyPermissions = subMenu?.permissions?.key;
                    const hasPermissions =
                      userPermissions &&
                      userPermissions.length > 0 &&
                      keyPermissions &&
                      userPermissions.includes(user[keyPermissions].toString());

                    if (hasPermissions || !subMenu?.permissions) {
                      const isSubmenuActive = location.pathname.startsWith(
                        subMenu.to,
                      );
                      return (
                        <StyledNavLink
                          key={subMenu.id}
                          to={subMenu.to}
                          onClick={toggleMobileMenuOpen}
                          active={isSubmenuActive}
                          data-cy="sidebar-nav-menu-item"
                        >
                          {subMenu.label}
                        </StyledNavLink>
                      );
                    }
                    return null;
                  })}
                </NavLinkContainer>
              </Collapse>
            )}
          </Fragment>
        );
      })}
    </Flex>
  );
}
