import { Flex, Text } from '@konta/ui';
import Loader from '@components/Loader';
import MetricCard from 'shared/components/MetricCard';
import DownloadComplianceOpinionBtn from 'shared/components/DownloadComplianceOpinionBtn';
import MetricGroup from 'shared/components/MetricGroup';
import ScoreGraph from 'shared/components/ScoreGraph/ScoreGraph';
import * as chat from '@util/chat';
import useMetricGroup from './useMetricGroup';

export default function MetricsGroup() {
  const {
    complianceOpinionStatusCheckedAt,
    complianceOpinionStatusLabel,
    complianceOpinionStatusColor,
    complianceOpinionStatusLoading,
    taxableEntityId,
    lastUpdated,
    satQualification,
    kontaScoreNumber,
  } = useMetricGroup();

  if (complianceOpinionStatusLoading) {
    return <Loader />;
  }
  return (
    <MetricGroup>
      <MetricCard
        headerAlignStart
        headerHoverText={
          <Flex column gap={6}>
            <Text>
              En esta sección encontrarás tu <Text bold>KontaEstatus</Text>.
            </Text>
            <Text>
              Esta calificación es una representación de tu{' '}
              <b>estatus en el SAT</b>
              según Konta.
            </Text>
            <Text>
              Tu calificación puede ir del <b>0% al 100%</b> (mientras más alto
              mejor) y representa <b>que tan bien estás con el SAT</b>. Sirve
              para estar tranquilo si no tienes pendientes y a saber cómo
              mejorar si estás mal.
            </Text>
            <Text>
              El objetivo principal de nuestro servicio es ayudarte a mejorar y
              mantener esta calificación alta.
            </Text>
            <Text>
              Calculamos el porcentaje de declaraciones atrasadas en los últimos
              12 meses con respecto a tus obligaciones
            </Text>
            <Text>
              ¿Tienes preguntas?{' '}
              <Text as="a" color="primary700" href="#" onClick={chat.open}>
                Contáctanos en el chat.
              </Text>
            </Text>
          </Flex>
        }
        headerTitle={`Tu calificación ante el sat es: ${satQualification}`}
        contentTitle={`Actualizada el día  ${lastUpdated}`}
        contentDescription="Se actualizará todos los meses"
        withSmallContentTitle
        graph={<ScoreGraph score={kontaScoreNumber} />}
      />
    </MetricGroup>
  );
}
