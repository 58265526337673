import { useState, ReactNode, ComponentProps } from 'react';
import { Card, CardContent, Flex, Text, CardRow, Button } from '@konta/ui';
import { Edit05LineIcon } from '@konta/icons';
import TooltipIcon from 'backoffice/components/TooltipIcon';

type CardRowProps = ComponentProps<typeof CardRow>;
interface MetricsTotalCardProps {
  title: string;
  content?: string;
  rows: {
    key: string;
    label: string;
    amount: string;
    isBold?: boolean;
    withBackground?: boolean;
    onClick?: () => void;
    button?: ReactNode;
    tooltip?: string | ReactNode;
    tooltipTitle?: string;
    buttonText?: string;
  }[];
  actions?: ReactNode;
  hoverElement?: ReactNode | boolean;
  striped?: CardRowProps['striped'];
  size?: CardRowProps['size'];
  tooltip?: string;
  tooltipTitle?: string;
}

export default function MetricsTotalCard({
  title,
  content,
  rows,
  actions,
  striped,
  hoverElement,
  size = 'small',
}: MetricsTotalCardProps) {
  const [itemWithHover, setItemWithHover] = useState<number | null>(null);
  const handleMouseEnter = (index: number) => {
    setItemWithHover(index);
  };
  const handleMouseLeave = () => {
    setItemWithHover(null);
  };
  return (
    <Card
      outlined
      css={{
        flex: '1',
      }}
    >
      <CardContent>
        <Flex
          gap="10"
          css={{ flexDirection: 'column', '@sm': { flexDirection: 'row' } }}
        >
          <Flex direction="column" css={{ flex: '1' }}>
            <Text l bold>
              {title}
            </Text>
            {!!content && (
              <Text s color="gray500">
                {content}
              </Text>
            )}
          </Flex>
          {!!actions && (
            <Flex
              css={{
                justifyContent: 'end',
                gap: '$8',
                '@sm': {
                  alignItems: 'end',
                  justifyContent: 'center',
                  minWidth: '10rem',
                  flexDirection: 'column',
                },
              }}
            >
              {actions}
            </Flex>
          )}
        </Flex>
      </CardContent>
      {rows.map(
        (
          {
            key,
            label,
            amount,
            isBold,
            withBackground,
            onClick,
            button,
            tooltip,
            tooltipTitle,
          },
          index,
        ) => (
          <CardRow
            {...(!!striped && { striped })}
            withBackground={withBackground}
            size={size}
            key={`${key}-${index}`}
            css={{ gap: 32 }}
            {...(!!hoverElement && {
              onMouseEnter: () => handleMouseEnter(index),
              onMouseLeave: handleMouseLeave,
            })}
          >
            <Flex
              justify={button ? 'start' : 'between'}
              css={{ flex: '1 0 0' }}
              {...(!!button && { gap: '8' })}
            >
              <Text
                bold={isBold}
                {...(!isBold && { color: 'gray700' })}
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '$8',
                }}
              >
                {label}
                {tooltip && (
                  <TooltipIcon title={tooltipTitle}>{tooltip}</TooltipIcon>
                )}
              </Text>

              {/* {!!hoverElement && itemWithHover === index && hoverElement} */}
              {!!button && button}
              {!!onClick && itemWithHover === index && (
                <Button
                  size="xs"
                  variant="text"
                  noFill
                  onClick={onClick}
                  leftIcon={<Edit05LineIcon />}
                >
                  Filtrar
                </Button>
              )}
            </Flex>
            <Text bold={isBold} {...(!isBold && { color: 'gray700' })}>
              {amount}
            </Text>
          </CardRow>
        ),
      )}
    </Card>
  );
}
