/* eslint-disable import/extensions */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'reactstrap';
import { NavLink, useLocation, useNavigate, Link } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, loginWithGoogleUser } from '@redux/actions';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import useFeature from '@hooks/useFeature';
import { Flex, Text, Button, Input } from '@konta/ui';
import {
  MailOutlineIcon,
  Lock1OutlineIcon,
  GoogleIcon,
  EyeOutlineIcon,
  EyeOffOutlineIcon,
} from '@konta/icons';
import impersonate from '@api/impersonate';
import api from '@api';
import { GOOGLE_LOGIN_ENABLED } from '@constants/featureFlags';
import { useQueryClient } from 'react-query';
import useSSOParams from 'shared/hooks/useSSOParams';
import useGoogleRecaptchaScript from 'shared/hooks/useGoogleRecaptchaScript';
import getErrorMessage from '@util/getErrorMessage';
import { NotificationManager } from '@components';
import Loader from 'shared/components/Loader';
import ScriptErrorInformation from 'shared/components/ScriptErrorInformation/ScriptErrorInformation';

export default function Login() {
  const { recaptcha, isGoogleScriptLoading, hasGoogleScriptError } =
    useGoogleRecaptchaScript();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [googleLoginEnabled] = useFeature(GOOGLE_LOGIN_ENABLED);
  const { user, loading } = useSelector((state) => state.authUser);
  const featureClient = useLDClient();
  const { search } = useLocation();
  const [passwordShown, setPasswordShown] = useState(false);
  const [loginForm, setLoginForm] = useState({});
  const client = useQueryClient();
  const { isFromSSO, SSO } = useSSOParams();

  const handleLoginEmailInputChange = (e) => {
    setLoginForm({
      ...loginForm,
      email: e.target.value,
    });
  };

  const handleLoginPasswordInputChange = (e) => {
    setLoginForm({
      ...loginForm,
      password: e.target.value,
    });
  };

  const handleCodeInputChange = (e) => {
    setLoginForm({
      ...loginForm,
      code: e.target.value,
    });
  };

  const onUserLoginWithTwoFactor = () => {
    dispatch(
      loginUser(
        { ...loginForm, otp_token: user?.otp_token, email: user?.email },
        navigate,
        loginForm.captchaToken,
        featureClient,
        SSO,
      ),
    );
  };

  const onUserLogin = (event) => {
    client.clear();
    event.preventDefault();
    recaptcha?.ready(async () => {
      try {
        client.clear();
        const token = await recaptcha.execute(
          process.env.REACT_APP_CAPTCHA_KEY || '',
          {
            action: 'login',
          },
        );
        dispatch(loginUser(loginForm, navigate, token, featureClient, SSO));
      } catch (error) {
        console.error(error);
        const errorMessage = getErrorMessage(error);
        NotificationManager.error(errorMessage, 'Iniciar sesión');
      }
    });
  };

  const googleLogin = useGoogleLogin({
    ux_mode: 'popup',
    onSuccess: async (codeResponse) => {
      const { data } = await api.post(
        '/auth/google_oauth2/callback',
        codeResponse,
      );
      dispatch(
        loginWithGoogleUser(loginForm, navigate, featureClient, { data }),
      );
    },
    onError: (codeResponse) => {
      console.log('codeResponse', codeResponse);
    },
  });

  const token = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return searchParams.get('token');
  }, [search]);

  const impersonateLogin = useCallback(async () => {
    if (!token) return;
    const retoolUser = await impersonate(token);

    localStorage.setItem('user_id', `${retoolUser.access_token}retool`);
    window.location.href = window.location.origin;
  }, [token]);

  useEffect(() => {
    impersonateLogin();
  }, [impersonateLogin]);

  if (token) return null;

  const isLoading = loading;
  const GRADIENT_BACKGROUND =
    'linear-gradient(to left bottom, #717cdb, #686dc9, #605eb7, #5750a5, #4e4294, #4e4294, #4e4294, #4e4294, #5750a5, #605eb7, #686dc9, #717cdb);, #6d72d8, #717cdb);';

  if (isGoogleScriptLoading) {
    return <Loader />;
  }

  if (hasGoogleScriptError) {
    return (
      <ScriptErrorInformation
        withResponsiveCenterContainer
        title="Ocurrió un error al cargar el captcha de Google, por favor recarga la página o contáctanos."
      />
    );
  }

  return (
    <Flex
      direction="row"
      css={{
        backgroundImage: GRADIENT_BACKGROUND,
        minHeight: '100vh',
        padding: '$32',
      }}
      justify="center"
      align="center"
    >
      <Flex
        direction="row"
        align="center"
        css={{
          py: '80px',
          width: '100%',
          px: '30px',
          '@sm': { width: '70%', px: '50px' },
          '@md': { width: '45%', px: '50px' },
          '@lg': { width: '35%', px: '50px' },
          background: 'white',
          borderRadius: '10px',
        }}
      >
        <Flex css={{ width: '100%' }}>
          <Flex direction="column" css={{ width: '100%' }}>
            <Flex css={{ mb: '40px' }}>
              {isFromSSO ? (
                <span>
                  <span className="logo-single" />
                </span>
              ) : (
                <NavLink to="/">
                  <span className="logo-single" />
                </NavLink>
              )}
            </Flex>
            <Flex direction="column" css={{ mb: '34px' }}>
              {user?.otp_token ? (
                <>
                  <Text color="gray800" x3l bold>
                    Autenticación de dos pasos
                  </Text>
                  <Text color="gray500" m css={{ mt: '$8' }}>
                    Ingresa el código que generó tu aplicación de autenticación
                  </Text>
                </>
              ) : (
                <>
                  <Text color="gray800" x4l bold>
                    Iniciar sesión
                  </Text>
                  <Text color="gray500" m css={{ mt: '$8' }}>
                    ¡Bienvenido de nuevo! Ingresa tus credenciales
                  </Text>
                </>
              )}
            </Flex>
            {user?.otp_token ? (
              <Flex direction="column">
                <Input
                  type="number"
                  label="Código de autenticación"
                  css={{ mb: '$8' }}
                  placeholder="Introduce el código de autenticación"
                  size="m"
                  data-openreplay-obscured
                  leftIcon={<Lock1OutlineIcon />}
                  onChange={handleCodeInputChange}
                  value={loginForm?.code}
                  data-testid="login-code"
                />

                <Button
                  color="primary600"
                  fontMedium
                  size="m"
                  css={{ mt: '18px' }}
                  disabled={!loginForm?.code || loginForm?.code?.length > 6}
                  onClick={onUserLoginWithTwoFactor}
                >
                  Iniciar sesión
                </Button>
              </Flex>
            ) : (
              <Form onSubmit={onUserLogin}>
                <Flex direction="column" gap="18">
                  <Input
                    type="email"
                    label="Correo electrónico"
                    autocomplete="email"
                    placeholder="Introduce tu correo electrónico"
                    size="m"
                    onChange={handleLoginEmailInputChange}
                    leftIcon={<MailOutlineIcon />}
                    defaultValue={loginForm?.email}
                    data-testid="login-email"
                    required
                  />

                  <Input
                    type={passwordShown ? 'text' : 'password'}
                    label="Contraseña"
                    autocomplete="current-password"
                    placeholder="Introduce tu contraseña"
                    size="m"
                    data-openreplay-obscured
                    rightIcon={
                      !passwordShown ? (
                        <EyeOutlineIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() => setPasswordShown(!passwordShown)}
                        />
                      ) : (
                        <EyeOffOutlineIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() => setPasswordShown(!passwordShown)}
                        />
                      )
                    }
                    onChange={handleLoginPasswordInputChange}
                    defaultValue={loginForm?.password}
                    leftIcon={<Lock1OutlineIcon />}
                    data-testid="login-password"
                    required
                  />
                </Flex>
                {!isFromSSO && (
                  <Flex justify="end" css={{ pt: '24px' }}>
                    <Text
                      color="primary700"
                      css={{ '&:hover': { color: '$gray500' } }}
                      as={Link}
                      to="/forgot-password"
                      s
                      underline
                    >
                      Olvidé mi contraseña
                    </Text>
                  </Flex>
                )}
                <Flex
                  direction="column"
                  justify="between"
                  align="stretch"
                  css={{ height: '160px' }}
                >
                  <Flex direction="column" align="stretch" css={{ pt: '24px' }}>
                    <Button
                      data-testid="login-submit"
                      color="primary600"
                      type="submit"
                      loading={isLoading}
                      disabled={isLoading}
                      fontMedium
                      size="m"
                      css={{ mb: '18px' }}
                    >
                      Iniciar sesión
                    </Button>
                    {googleLoginEnabled && !isFromSSO && (
                      <Button
                        color="gray"
                        variant="outlined"
                        loading={isLoading}
                        disabled={isLoading}
                        leftIcon={<GoogleIcon />}
                        onClick={() => {
                          googleLogin();
                        }}
                        fontMedium
                        size="m"
                        u
                        css={{ mb: '18px' }}
                      >
                        Iniciar sesión con Google
                      </Button>
                    )}
                  </Flex>
                  {!isFromSSO && (
                    <Flex justify="center" align="end">
                      <Text color="gray400">
                        ¿No tienes cuenta?{' '}
                        <Text
                          color="primary700"
                          css={{ '&:hover': { color: '$gray500' } }}
                          as={Link}
                          to="/register"
                          s
                          underline
                        >
                          Regístrate
                        </Text>
                      </Text>
                    </Flex>
                  )}
                </Flex>
              </Form>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
