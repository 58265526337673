import { useCallback } from 'react';
import { useMutation, UseMutationOptions } from 'react-query';
import { putDeclarationEntries } from 'shared/api/declarationEntries';
import type {
  DeclarationEntry,
  DeclarationEntryUpdatePayload,
} from 'types/entities';
import type { AxiosRequestConfig } from 'axios';

export default function usePutDeclarationEntries(
  axiosConfig?: AxiosRequestConfig<DeclarationEntry[]>,
  queryOptions?: UseMutationOptions<
    DeclarationEntry[],
    unknown,
    DeclarationEntryUpdatePayload
  >,
) {
  const putDeclarationEntriesRequest = useCallback(
    (payload: DeclarationEntryUpdatePayload) =>
      putDeclarationEntries(payload, axiosConfig),
    [axiosConfig],
  );
  return useMutation(putDeclarationEntriesRequest, queryOptions);
}
