import { call } from 'redux-saga/effects';
import { StatusHandler, ErrorHandler } from '@util/Saga';
import * as Sentry from '@sentry/react';
import api from '@api';
import { setUserDataToOpenReplay } from '@util/OpenReplay';
import gtmEvent from '@util/gtmEvent';
import { identifySegment } from '@util/Segment';
import {
  load as loadIntercom,
  boot as bootIntercom,
  formatInfo,
} from '@util/Intercom';
import { onboardingUserFailure, onboardingUserSuccess } from '../actions';

export default function* ({ payload: { user, options, featureClient } }) {
  try {
    const response = yield call(api.put, '/onboarding', user, {
      headers: { Authorization: `${localStorage.getItem('user_id')}` },
    });
    const {
      access_token,
      email,
      phone_number,
      id,
      first_name,
      taxable_entity,
    } = response.data;
    localStorage.setItem('user_id', access_token);
    yield call(StatusHandler, {
      response,
      options,
      title: 'Exito!',
      message: 'La contraseña fue actualizada correctamente',
      success: onboardingUserSuccess,
      failure: onboardingUserFailure,
    });
    gtmEvent('AppEvent', 'onboardingSuccess');
    Sentry.configureScope((scope) => {
      scope.setUser({
        id,
        email,
        username: first_name,
        phone_number,
      });
    });
    const onboardUser = response.data;
    setUserDataToOpenReplay({
      user: onboardUser,
      taxable_entity,
    });
    const featureFlags = yield featureClient.identify({
      kind: 'user',
      key: id,
      email,
      firstName: first_name,
      hasSubscription: taxable_entity?.status !== 'demo',
    });
    identifySegment(onboardUser.id, featureFlags);
    yield loadIntercom();
    yield bootIntercom(formatInfo({ user }));
  } catch (error) {
    yield call(ErrorHandler, {
      error,
      action: onboardingUserFailure,
    });
  }
}
