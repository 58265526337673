import {
  developClientSideId,
  productionClientSideId,
  stagingClientSideId,
} from './launchDarkly';

// eslint-disable-next-line import/prefer-default-export
export const ENVIRONMENTS = {
  production: {
    api: 'https://api.konta.com/api/v1',
    host: 'https://api.konta.com',
    env: 'production',
    launchDarklyClientSideId: productionClientSideId,
  },
  develop: {
    api: 'https://api-develop.konta.com/api/v1',
    host: 'https://api-develop.konta.com',
    env: 'develop',
    launchDarklyClientSideId: developClientSideId,
  },
  staging: {
    api: 'https://api-staging.konta.com/api/v1',
    host: 'https://api-staging.konta.com',
    env: 'staging',
    launchDarklyClientSideId: stagingClientSideId,
  },
};
