import { useEffect, useMemo, useState } from 'react';
import type { ColumnFiltersState } from '@tanstack/react-table';
import useCfdisPagination from 'shared/hooks/useCfdisPagination';
import useCommonCfdiColumns from '../useCommonCfdiColumns';
import getRansackFilters from '../getRansackFilters';

interface UseEmittedCfdisProps {
  setCfdi: (cfdi: any) => void;
  setTogglePdf: () => void;
  setToggleComplement: () => void;
  handleShowReports: (original: any) => void;
}

export default function useEmittedCfdis({
  setCfdi,
  setTogglePdf,
  setToggleComplement,
  handleShowReports,
}: UseEmittedCfdisProps) {
  const [filters, setFilters] = useState<ColumnFiltersState>([]);
  const ransackFilters = useMemo(() => getRansackFilters(filters), [filters]);
  const columns = useCommonCfdiColumns({
    setCfdi,
    setTogglePdf,
    setToggleComplement,
    handleShowReports,
  });

  const { pagination, cfdisLoading, cfdis } = useCfdisPagination({
    apiVersion: 2,
    params: ransackFilters,
  });

  return {
    columns,
    pagination,
    cfdis,
    cfdisLoading,
    setFilters,
  };
}
