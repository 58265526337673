import { FormikContextType, FormikProvider, Form } from 'formik';
import { ReactNode, RefObject } from 'react';
import FormWrapper from '@components/FormWrapper';
import { CSS } from '@konta/ui/dist/stitches.config';

export interface FormikWrapperProps<T> {
  formik: FormikContextType<T>;
  children: ReactNode;
  withWrapper?: boolean;
  isReadOnly?: boolean;
  isClickable?: boolean;
  wrapperCss?: CSS;
  formRef?: RefObject<HTMLFormElement>;
  id?: string;
}

export default function FormikWrapper<T>({
  formik,
  children,
  withWrapper = true,
  isReadOnly,
  isClickable = true,
  wrapperCss,
  formRef,
  id,
}: FormikWrapperProps<T>) {
  return (
    <FormikProvider value={formik}>
      <Form
        id={id}
        ref={formRef}
        {...(isReadOnly && {
          style: {
            opacity: 0.7,
            backgroundColor: 'white',
            pointerEvents: 'none',
            userSelect: 'none',
            cursor: 'not-allowed',
          },
        })}
        {...(!isClickable && {
          style: {
            pointerEvents: 'none',
          },
        })}
        style={{
          width: '100%',
        }}
      >
        {withWrapper ? (
          <FormWrapper css={wrapperCss}>{children}</FormWrapper>
        ) : (
          children
        )}
      </Form>
    </FormikProvider>
  );
}
