import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import { useToggle } from 'rooks';
import { Alert, Flex, Text } from '@konta/ui';
import { FOREIGN_INVOICES } from '@constants/reactQueries';
import { NotificationManager } from '@components';
import getErrorMessage from '@util/getErrorMessage';
import FormikTextarea from '@components/FormikTextarea';
import FormikTextInput from '@components/FormikTextInput';
import FormikKontaSelect from '@components/FormikKontaSelect';
import ForeignInvoiceDropfile from '@components/ForeignInvoiceTableForm/ForeignInvoiceDropfile';
import {
  declarationEntriesCreate,
  declarationEntriesUpdate,
} from '@redux/declaration_entries/actions';
import useDeclarationEntries from '@hooks/useDeclarationEntries';
import useSelectedWorkflow from '@hooks/useSelectedWorkflow';
import useTaxesPayers from 'shared/hooks/useForeignTaxesPayers';
import postForeignInvoice from '@api/postForeignInvoice';

const numberValidation = yup
  .number()
  .required('Campo requerido')
  .moreThan(0, 'Debe ser mayor a 0');

const validationSchema = yup
  .object()
  .nullable()
  .shape({
    isFromWorkflow: yup.string().nullable(),
    fiscalRegime: yup
      .object()
      .nullable()
      .when('isFromWorkflow', {
        is: (value) => value === 'true',
        then: yup.object().nullable().required('Campo requerido'),
      }),
    description: yup.string().required('Campo requerido'),
    amount: numberValidation,
    iva: yup.number().min(0, 'Debe ser mayor o igual a 0'),
    foreignTaxpayer: yup
      .object()
      .nullable()
      .when('iva', {
        is: (value) => value > 0,
        then: yup.object().nullable().required('Campo requerido'),
      }),
    file: yup.mixed().required('Documento requerido'),
  });

function foreignInvoiceToValue(product, isFromWorkflow, defaultRegimeOption) {
  if (product?.source?.foreign_invoice) {
    const { document, iva, amount, foreign_taxpayer } =
      product.source.foreign_invoice;
    return {
      description: product?.description,
      amount,
      iva,
      file: document,
      ...(foreign_taxpayer && {
        foreignTaxpayer: {
          label: foreign_taxpayer.social_reason,
          key: foreign_taxpayer.id,
          value: foreign_taxpayer.id,
        },
      }),
    };
  }
  return {
    description: '',
    amount: '',
    iva: '',
    foreignTaxpayer: null,
    fiscalRegime: defaultRegimeOption || null,
    file: null,
    isFromWorkflow,
  };
}

function valuesToPayload({
  foreignInvoice,
  values,
  declaration,
  isFromWorkflow,
}) {
  const data = new FormData();
  const { iva, amount, description, file, foreignTaxpayer, fiscalRegime } =
    values;
  const taxPayerId = foreignTaxpayer?.value;

  const declarationId = declaration?.id;
  const sourceId = foreignInvoice?.source_id;
  const id = foreignInvoice?.id;

  if (file && file instanceof File) {
    data.append(
      'declaration_entry[source_attributes][document_attributes][attachment]',
      file,
    );
    data.append(
      'declaration_entry[source_attributes][document_attributes][category_id]',
      '6',
    );
  }

  if (taxPayerId) {
    data.append(
      'declaration_entry[source_attributes][foreign_taxpayer_id]',
      taxPayerId,
    );
  }

  if (sourceId) {
    data.append('declaration_entry[source_attributes][id]', sourceId);
  }
  data.append(
    'declaration_entry[source_attributes][taxable_entity_id]',
    declaration.taxable_entity_id,
  );
  data.append('declaration_entry[source_attributes][iva]', iva || '0');
  data.append(
    'declaration_entry[source_attributes][period]',
    declaration?.start_date,
  );
  data.append('declaration_entry[is_accountable]', 'true');
  data.append('declaration_entry[source_attributes][amount]', amount);
  data.append('declaration_entry[source_type]', 'ForeignInvoice');
  data.append('declaration_entry[description]', description);
  data.append('declaration_entry[accounting_status]', 'annual');
  data.append('declaration_entry[accounting_date]', declaration.start_date);
  data.append('declaration_entry[declaration_entry_type]', 'foreign_invoices');
  if (sourceId) {
    data.append('declaration_entry[source_attributes][id]', sourceId);
  }
  if (isFromWorkflow) {
    const fiscalRegimeDeclarationId = fiscalRegime.declarationId;
    data.append('declaration_entry[declaration_id]', fiscalRegimeDeclarationId);
  } else {
    data.append('declaration_entry[declaration_id]', declarationId);
  }

  return {
    body: data,
  };
}

function valuesToForeignInvoicePayload({ foreignInvoice, values }) {
  const data = new FormData();
  const sourceId = foreignInvoice?.source_id;
  const id = foreignInvoice?.id;
  const { iva, amount, file, foreignTaxpayer } = values;
  const taxPayerId = foreignTaxpayer?.value;

  if (file && file instanceof File) {
    data.append('document_attributes[attachment]', file);
    data.append('document_attributes[category_id]', '6');
  }

  if (iva > 0) {
    data.append('foreign_taxpayer_id', taxPayerId);
  }

  data.append('iva', iva);
  data.append('period', dayjs().subtract(1, 'year').format('YYYY-MM-DD'));
  data.append('amount', amount);

  return data;
}

export default function ForeignInvoiceForm({
  foreignInvoice,
  onSubmit,
  innerRef,
  onLoading,
  readOnly,
  isAnnualProcess,
  isFromWorkflow = false,
  regimeOptions = [],
  taxableEntityPreferences,
  defaultRegimeOption,
}) {
  const queryClient = useQueryClient();
  const isEditingMode = Boolean(foreignInvoice);
  const dispatch = useDispatch();
  const { workflow } = useSelectedWorkflow();
  const { loading: isLoading } = useDeclarationEntries();

  const initialValues = useMemo(
    () =>
      foreignInvoiceToValue(
        foreignInvoice,
        isFromWorkflow,
        defaultRegimeOption,
      ),
    [foreignInvoice, isFromWorkflow],
  );

  const addNewDocument = (newDocument) => {
    queryClient.setQueryData([FOREIGN_INVOICES], (oldForeignDocuments) => {
      if (!oldForeignDocuments) return []; // only for ts validation
      return [...oldForeignDocuments, newDocument];
    });
  };

  const uploadForeignInvoice = useMutation(postForeignInvoice);

  const addAnnualForeignInvoice = async (values) => {
    try {
      onLoading(true);
      const payload = valuesToForeignInvoicePayload({
        foreignInvoice,
        values,
        isAnnualProcess,
      });
      const response = await uploadForeignInvoice.mutateAsync(payload);
      addNewDocument(response);
      onSubmit();
      NotificationManager.success(
        'Se ha guardo la factura extranjera',
        'Factura subida',
        3000,
      );
    } catch (error) {
      const message = getErrorMessage(error);
      NotificationManager.error(message, 'Error', 10000);
    } finally {
      onLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    if (isAnnualProcess) {
      addAnnualForeignInvoice(values);
      return;
    }
    const declaration = workflow.declarations[0];
    const payload = valuesToPayload({
      foreignInvoice,
      values,
      declaration,
      isFromWorkflow,
    });
    Object.assign(payload, {
      callback: onSubmit,
    });

    if (payload.id) {
      dispatch(declarationEntriesUpdate(payload));
    } else {
      dispatch(declarationEntriesCreate(payload));
    }
  };

  const { taxesPayers } = useTaxesPayers();
  const taxesPayersOptions = useMemo(() => {
    return taxesPayers.map((taxPayer) => ({
      label: taxPayer.social_reason,
      key: taxPayer.id,
      value: taxPayer.id,
    }));
  }, [taxesPayers]);

  const fileName = useMemo(() => {
    const document = foreignInvoice?.file;
    if (document) {
      const url = new URL(document);
      const urlParams = new URLSearchParams(url?.search);
      const param = urlParams.get('response-content-disposition');
      const file = param?.split?.('filename')?.[1]?.split?.('"')?.[1];
      if (file) {
        return file;
      }
    }
    return '';
  }, [foreignInvoice?.source?.foreign_product?.documents]);

  const [hasFile, toggleHasFile] = useToggle(!!fileName);

  useEffect(() => {
    onLoading(isLoading);
  }, [isLoading, onLoading]);

  const eventHandlers = {
    addedfile: (file) => {
      innerRef.current.setFieldValue('file', file);
    },
    removedfile: (file) => {
      const currentFile = innerRef.current.values.file;
      if (file.size === currentFile?.size && file.name === currentFile?.name) {
        innerRef.current.setFieldValue('files', null);
      }
    },
  };

  const deleteFile = (setFieldValue) => {
    setFieldValue('file', null);
    toggleHasFile();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      innerRef={innerRef}
    >
      {({ values, errors, setFieldValue }) => (
        <Flex direction="column" gap={24} css={{ mt: '24px' }}>
          {isFromWorkflow && (
            <FormikKontaSelect
              name="fiscalRegime"
              label="Régimen Fiscal *"
              options={regimeOptions}
              isDisabled={
                readOnly || !!taxableEntityPreferences.preferred_fiscal_regime
              }
            />
          )}
          <Flex gap={20}>
            <FormikTextarea
              rows={1}
              name="description"
              label="Descripción *"
              placeholder="Máximo 1000 caracteres"
              readOnly={readOnly}
            />
            <FormikTextInput
              type="number"
              name="amount"
              label="Cantidad *"
              placeholder="Ejemplo 200 pesos"
              readOnly={readOnly}
            />
          </Flex>
          <Flex gap={20}>
            <FormikTextInput
              type="number"
              name="iva"
              label="Monto de IVA"
              placeholder="Ejemplo 16"
              readOnly={readOnly}
            />
            <FormikKontaSelect
              name="foreignTaxpayer"
              label={`Nombre del Emisor ${values.iva > 0 ? '*' : ''}`}
              options={taxesPayersOptions}
              isDisabled={!values.iva || values.iva <= 0 || readOnly}
            />
          </Flex>
          {isEditingMode && fileName && hasFile && (
            <Alert
              {...(!readOnly && { onClose: () => deleteFile(setFieldValue) })}
            >
              <b>Nombre documento: </b>
              {fileName}
            </Alert>
          )}
          {!hasFile && !readOnly && (
            <Flex direction="column" gap={6} css={{ flex: 1 }}>
              <ForeignInvoiceDropfile events={eventHandlers} />
              {errors.file && (
                <Text xs lineHeight="xs" color="error500">
                  {errors.file}
                </Text>
              )}
            </Flex>
          )}
        </Flex>
      )}
    </Formik>
  );
}

ForeignInvoiceForm.propTypes = {
  foreignInvoice: PropTypes.instanceOf(Object),
  // eslint-disable-next-line react/forbid-prop-types
  innerRef: PropTypes.any,
  onSubmit: PropTypes.func,
  onLoading: PropTypes.func,
  readOnly: PropTypes.bool,
};

ForeignInvoiceForm.defaultProps = {
  foreignInvoice: null,
  innerRef: null,
  onSubmit: null,
  onLoading: null,
  readOnly: false,
};
